<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="450">
      <v-card relative class="backgroundW">
        <v-card-title class="text-h6 font-weight-bold">
          <img src="../../assets/img/png/watchOut.png" height="30" class="mx-2" alt="erp" />
          <h5>
            <span>{{ $t('Are you sure to delete') }}</span>
            <span class="mx-1">{{ item }}</span>
            {{ $t('?') }}
          </h5>
        </v-card-title>
<!-- 
        <v-col cols="12" sm="12">
          <div class="body-2 px-5">
            <p>{{ $t('Erase it permanently, it will be impossible to restore it again, we will wait 5 seconds When the final scan button is activated') }}</p>
          </div>
        </v-col> -->

        <v-col cols="12" class="py-4">
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
                outlined
                @click="closeDialog"
                class="shadow"
                color="error"
                style="width: 100%; text-align: start"
              >{{ $t('close') }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="error"
                @click="returnData"
                class="shadow"
                :disabled="counter !== 0"
                style="width: 100%; text-align: start"
              >
                <v-icon class="mx-1" small left>mdi-trash-can</v-icon>
                {{ $t('delete') }}
                <span v-if="counter !== 0">({{ $t(counter) }})</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import { mdiCheckCircle } from "@mdi/js";
export default {
  name: "DeleteConfirmation",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    item: { default: "" },

  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      mdiCheckCircle: mdiCheckCircle,
      counter: 0
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.backValueMethod(false);
      this.counter = 0
    },
    returnData() {
      this.dialog = false
      this.backValueMethod(true);
      // this.counter = 5
    },
    countDown() {
      if (this.counter > 0) {
        setTimeout(() => {
          this.counter = this.counter - 1
          if (this.counter !== 0) {
            this.countDown()
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.countDown();
  }
};
</script>
