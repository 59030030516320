<template>
  <section>
    <v-container fluid>
      <v-card elevation="0" class="ma-0 shadow mt-5 pa-0 backgroundW rounded-lg">
        <v-row justify="start" justify-md="space-between" align="center">
          <!-- Header -->
          <v-col cols="12" md="auto" sm="12" class="px-md-10">
            <v-tabs
              @change="getData()"
              active-class="primary white--text"
              color="primary"
              v-model="tab"
              height="39"
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab
                v-for="item in tabsItems"
                :key="item"
                class="font-weight-bold"
                @click="activeTab = item"
              >{{ $t(item) }}</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="10" md="auto" sm="10" class="px-md-10 d-md-flex align-center">
            <v-text-field
              v-model="pageData.search"
              @keydown.enter="(e) => getSearch(e.target.value)"
              @click:clear="(e) => getSearch(e.target.value)"
              @click:prepend-inner="getSearch(pageData.search)"
              prepend-inner-icon="mdi-magnify"
              :placeholder="
                pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('Search')
              "
              style="min-width: 280px;"
              single-line
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>
            <v-btn
              :to="'/' + pageData.controlRoute"
              height="39"
              class="my-md-2 my-5 mx-2 shadow"
              color="primary"
            >
              <v-icon dark left>mdi-plus-circle</v-icon>
              {{ $t("create") }}
            </v-btn>
          </v-col>
          <!-- End Of Header -->
          <!-- Table -->
          <v-col cols="12" sm="12" class="px-3 pt-0">
            <DataTable
              :isLoading="pageData.isLoading"
              :options="pageData.options"
              :data="pageData.rows"
              :header="pageData.tableHeader"
              :backValueMethod="setClickRow"
              :editValueMethod="edit"
              :deleteValueMethod="deleteMethod"
              :sendContract="chooseTypeDialog"
              :restoreValueMethod="restoreMethod"
              :toggleActiveMethod="toggleActiveMethod"
              :pageData="pageData"
              :print="print"
            ></DataTable>
          </v-col>
          <v-col cols="12" sm="12" v-if="!pageData.isLoading">
            <Pagination
              :pagination="pageData.pagination"
              :limit="pageData.rowsNumber"
              :backValueMethod="changePage"
              :isLoading="pageData.isLoading"
            ></Pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <SendContractVue :dialog="dialog" :backValueMethod="sendContract" />
  </section>
</template>

<script>

import DataTable from "../../components/DataTable.vue";
import Pagination from "../../components/ui/Pagination.vue";
import SendContractVue from "../../components/modals/SendContract.vue";
export default {
  name: "ContractsView",

  data: () => ({
    pageData: {
      url: "/contracts",
      controlRoute: "contract",
      entityName: "contracts",
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},

    },
    tab: null,
    selectedItem: null,
    dialog: false,
    activeTab: "all",
    tabsItems: [
      'all contracts', 'new','active', 'pending', 'accepted', 'rejected', 'deleted'
    ]
  }),
  components: {
    DataTable,
    Pagination,
    SendContractVue
  },
  computed: {
    status() {
      switch (this.activeTab) {
        case "all contracts":
          return null
        case "new":
          return 1
        case "active":
          return 100
        case "pending":
          return 2
        case "accepted":
          return 3
        case "rejected":
          return 4
        case "deleted":
          return 0
        default:
          return null;
      }
    },
    endPoint() {
      switch (this.status) {
        case null:
          return `${this.pageData.entityName}?rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
        case 0:
          return `${this.pageData.entityName}?is_deleted=1&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
        case 100:
          return `${this.pageData.entityName}?contract_active=1&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
        default:
          return `${this.pageData.entityName}?contract_status=${this.status}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
      }
    }
  },
  watch: {
    status() {
      if (this.status == 0) {
        this.pageData.isTrashed = 0;
      }
      else {
        this.pageData.isTrashed = 1;
      }
    },

  },
  mounted() {

    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t("contract number"),
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("customer name"),
          sortable: true,
          value: "customer_name",
        },
        {
          text: this.$i18n.t("customer mobile"),
          sortable: true,
          value: "phone",
        },
        {
          text: this.$i18n.t("send contract"),
          sortable: true,
          value: "send_contract",
        },
        {
          text: this.$i18n.t("contract status"),
          sortable: true,
          value: "contract_status",
        },
        {
          text: this.$i18n.t("active"),
          sortable: true,
          value: "contract_active",
        },
        {
          text: this.$i18n.t("created_at"),
          sortable: true,
          value: "add_date",
        },
        { text: this.$i18n.t("Settings"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: true,
        delete: true,
        edit: true,
        restore: true,
        view: true,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(this.endPoint)
        .then((response) => {
          if (response.check) {
            console.log(response);
            this.pageData.rows = response.data.contracts.data;
            this.pageData.page = response.data.contracts.current_page;
            this.pageData.pagination = {
              page: response.data.contracts.current_page,
              totalPages: response.data.contracts.last_page,
              per_page: response.data.contracts.per_page,
              totalRows: response.data.contracts.total,
            };
            this.pageData.isLoading = false;
          }
        });

    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`${this.pageData.entityName}/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    chooseTypeDialog(item) {
      this.dialog = true;
      this.selectedItem = item
    },
    sendContract(type, status) {
      this.dialog = false
      if (status == true) {
        this.$api.POST_METHOD(`send_contract/${this.selectedItem.id}`, { type: type }).then(() => {
          // if (response.check) {
          //   this.getData();
          // }
        });
      }
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`${this.pageData.entityName}_restore/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    toggleActiveMethod(row) {
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${row.id}`, null).then(() => {
        // if (response.check) {
        //   this.getData();
        // }
      });
    },
    print(row) {
      this.$api.DOWNLOAD_METHOD(`print_contract/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {

    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>

<style lang="scss">
.activeTab {
  background: transparent !important;
  border-bottom: 2px solid #2d68b5 !important;
}
</style>
