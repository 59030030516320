<template>
  <v-container fluid>
    <v-row justify="space-between" align="center">
      <v-col cols="6" md="2">
        <img
          src="../../assets/logo.svg"
          class="d-block ma-auto"
          style="max-height: 40px;"
          alt="smart form"
        />
      </v-col>
      <v-col cols="8" class="d-none d-md-block">
        <v-row align="center" justify="center">
          <v-col cols="auto" class="d-md-block d-none">
            <v-btn
              to="/"
              active-class="primary--text"
              color="black--text"
              class="font-weight-bold"
              height="40"
              
              plain
              depressed
            >{{ $t('customers') }}</v-btn>
          </v-col>
          <v-col cols="auto" class="d-md-block d-none">
            <v-btn
              to="/contracts"
              active-class="primary--text"
              color="black--text"
              class="font-weight-bold"
              height="40"
              
              plain
              depressed
            >{{ $t('contracts') }}</v-btn>
          </v-col>
          <v-col cols="auto" class="d-md-block d-none">
            <v-btn
              to="/invoices"
              active-class="primary--text"
              color="black--text"
              class="font-weight-bold"
              height="40"
              
              plain
              depressed
            >{{ $t('services invoices') }}</v-btn>
          </v-col>
          <v-col cols="auto" class="d-md-block d-none">
            <v-btn
              to="/renew-contracts"
              active-class="primary--text"
              color="black--text"
              class="font-weight-bold"
              height="40"
              
              plain
              depressed
            >{{ $t('renew contracts') }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="2" class="d-none d-md-block">
        <v-btn
          color="backgroundW "
          depressed
          style="border: 0.5px solid #EBEBEB !important"
          @click="logout"
        >
          <img src="../../assets/img/png/shutdown.png" class="mx-2" alt="logout" />
          {{ $t('logout') }}
        </v-btn>
      </v-col>
      <v-col col="6" class="d-md-none">
        <AppbarMenu></AppbarMenu>
      </v-col>
    </v-row>
  </v-container>
</template>




<script>
import AppbarMenu from "./AppbarMenu.vue";
export default {
  name: "Appbar",
  computed: {

  },

  data: () => ({

  }),
  components: {
    AppbarMenu
  },
  methods: {
    logout() {
      this.$api.LOGOUT();
    },
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
