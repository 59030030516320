import Vue from "vue";
import VueRouter from "vue-router";
import Error404 from "../views/Error404.vue";
import DashboardVue from "../views/Dashboard.vue";
import LoginPageVue from "../views/LoginPage.vue";
import AuthVue from "../views/Auth.vue";
import Error500Vue from "../views/Error500.vue";
import CustomerView from "../views/Customers/CustomerView.vue";
import CustomerControl from "../views/Customers/CustomerControl.vue";
import ContractsView from "../views/Contracts/ContractsView.vue";
import ContractsRenew from "../views/Contracts/ContractsRenew.vue";
import ContractRenewControl from "../views/Contracts/ContractRenewControl.vue";
import ContractControl from "../views/Contracts/ContractControl.vue";
import InvoicesView from "../views/Invoices/InvoicesView.vue";
import InvoiceControl from "../views/Invoices/InvoiceControl.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardVue,
    children: [
      // Customer
      {
        path: "/",
        name: "CustomerView",
        component: CustomerView,
      },
      {
        path: "/customer",
        name: "CustomerControl",
        component: CustomerControl,
      },
      {
        path: "/customer/:id",
        name: "CustomerControlEdit",
        component: CustomerControl,
      },
      //  Contracts
      {
        path: "/contracts",
        name: "ContractsView",
        component: ContractsView,
      },
      {
        path: "/renew-contracts",
        name: "ContractsRenew",
        component: ContractsRenew,
      },
      {
        path: "/contract",
        name: "ContractControl",
        component: ContractControl,
      },
      {
        path: "/contract/:id",
        name: "ContractControlEdit",
        component: ContractControl,
      },
      {
        path: "/renew-contract/:id",
        name: "ContractRenewControl",
        component: ContractRenewControl,
      },
      //  invoices
      {
        path: "/invoices",
        name: "InvoicesView",
        component: InvoicesView,
      },
      {
        path: "/invoice",
        name: "InvoiceControl",
        component: InvoiceControl,
      },
      {
        path: "/invoice/:id",
        name: "InvoiceControlEdit",
        component: InvoiceControl,
      },
    ],
  },
  {
    path: "auth",
    name: "Auth",
    component: AuthVue,
    children: [
      {
        path: "/",
        name: `LoginPage`,
        component: LoginPageVue,
      },
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPageVue,
      },
    ],
  },
  {
    path: "/error500",
    name: "Error500",
    component: Error500Vue,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
