
import vuetify from "../plugins/vuetify";
import i18n from "../plugins/i18n";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    translation: localStorage.getItem("language") == "en" ? i18n.messages.en : i18n.messages.ar,
    isRTL: vuetify.rtl,
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    requiredRule: [(v) => !!v || i18n.t("Field is required")],
    notRequiredRule: [],
    emailRule: [
      // (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      v => !!v || i18n.t("Field is required"),
      v => /.+@.+\..+/.test(v) ||  i18n.t("Please enter email address"),
    ],
    usernameRule: [
      v => !!v ||  i18n.t('Username is required')
    ],
    mobileRule: [
      (v) =>
        !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
        i18n.t("Please enter mobile number"),
    ],
    passwordRule: [
      (v) => !!v || i18n.t("Password is required"),
      (v) =>
        (v && v.length >= 5) ||
        i18n.t("Password must be greater than 5 characters"),
    ],
    favourites: localStorage.getItem("favourites")
      ? JSON.parse(localStorage.getItem("favourites"))
      : [],
  },
  mutations: {
    DARK_THEME(state, status) {
      state.darkTheme = status;
    },
    ACTIVE_LANGUAGE(state, lang) {
      state.language = lang;
      state.translation = lang == "ar" ? i18n.messages.ar  : i18n.messages.en ;
      i18n.locale = lang,
      console.log(state.translation);
    },
    UPDATE_FAVOURITES(state, favourites) {
      state.favourites = favourites;
      setTimeout(() => {
        localStorage.setItem("favourites", JSON.stringify(state.favourites));
      }, 10);
    },
  },
  actions: {
    changeLanguage({ state }) {
      if (state.language == "en") {
        // commit("ACTIVE_LANGUAGE", "ar");
        // vuetify.framework.lang.current = ar;
        localStorage.setItem("language", "ar");
        // return (vuetify.framework.rtl = true);
        window.location.reload(true);
      } else {
        // commit("ACTIVE_LANGUAGE", "en");
        // vuetify.framework.lang.current = en;
        localStorage.setItem("language", "en");
        // return (vuetify.framework.rtl = false);
        window.location.reload(true);
      }
    },
    darkTheme({ commit, state }) {
      localStorage.setItem("darkTheme", !state.darkTheme);
      commit("DARK_THEME", !state.darkTheme);
      return (vuetify.framework.theme.dark = state.darkTheme);
    },
    addFavourites({ commit, state }, favourites) {
      if (!state.favourites.some((fav) => fav.link === favourites.link)) {
        return commit("UPDATE_FAVOURITES", state.favourites.concat(favourites));
      } else {
        const removeIndex = state.favourites.findIndex((fav) =>  fav.link === favourites.link );
        state.favourites.splice(removeIndex, 1)
        return commit("UPDATE_FAVOURITES", state.favourites);
      }
    },
  },
};
