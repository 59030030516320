<template>
  <section>
    <v-container fluid>
      <v-card elevation="0" class="ma-0 shadow mt-5 pa-0 backgroundW rounded-lg">
        <v-row justify="space-between" align="center">
          <!-- Header -->
          <v-col cols="12" lg="4" sm="auto" class="px-10">
            <v-text-field
              v-model="pageData.search"
              @keydown.enter="(e) => getSearch(e.target.value)"
              @click:clear="(e) => getSearch(e.target.value)"
              @click:prepend-inner="getSearch(pageData.search)"
              prepend-inner-icon="mdi-magnify"
              :placeholder="
                pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('Search')
              "
              single-line
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="px-10">
            <v-btn
              class="shadow mx-2"
              v-if="pageData.isTrashed == 0"
              @click="pageData.isTrashed = 1; getData();"
              depressed
            >
              <v-icon color="primary" left>mdi-pin</v-icon>
              {{ $t("pin") }}
            </v-btn>
            <v-btn
              class="shadow mx-2"
              :color="$store.state.Settings.darkTheme ? '#393939' : '#D1D1D1'"
              v-if="pageData.isTrashed == 1"
              @click="pageData.isTrashed = 0; pageData.page = 1; getData();"
              depressed
            >
              <v-icon color="error" left>mdi-minus-box</v-icon>
              {{ $t("deleted") }}
            </v-btn>
            <v-btn :to="'/' + pageData.controlRoute" class="my-2 mx-2 shadow" color="primary">
              <v-icon dark left>mdi-plus-circle</v-icon>
              {{ $t("create") }}
            </v-btn>
          </v-col>
          <!-- End Of Header -->
          <!-- Table -->
          <v-col cols="12" sm="12" class="px-3 pt-0">
            <DataTable
              :isLoading="pageData.isLoading"
              :options="pageData.options"
              :data="pageData.rows"
              :header="pageData.tableHeader"
              :backValueMethod="setClickRow"
              :editValueMethod="edit"
              :deleteValueMethod="deleteMethod"
              :restoreValueMethod="restoreMethod"
              :pageData="pageData"
            ></DataTable>
          </v-col>
          <v-col cols="12" sm="12" v-if="!pageData.isLoading">
            <Pagination
              :pagination="pageData.pagination"
              :limit="pageData.rowsNumber"
              :backValueMethod="changePage"
              :isLoading="pageData.isLoading"
            ></Pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </section>
</template>

<script>

import DataTable from "../../components/DataTable.vue";
import Pagination from "../../components/ui/Pagination.vue";
export default {
  name: "CustomerView",

  data: () => ({
    pageData: {
      url: "/",
      controlRoute: "customer",
      entityName: "customers",
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
  }),
  components: {
    DataTable,
    Pagination,
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();

  },
  methods: {
    pageMainData() {
      this.pageData.tableHeader = [
        {
          text: "#",
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("customer name"),
          sortable: true,
          value: "name",
        },
        {
          text: this.$i18n.t("customer mobile"),
          sortable: true,
          value: "phone",
        },
        {
          text: this.$i18n.t("created_at"),
          sortable: true,
          value: "add_date",
        },
        { text: this.$i18n.t("Settings"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: false,
        delete: true,
        edit: true,
        restore: true,
        view: true,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {

      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(
          `${this.pageData.entityName}?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
        )
        .then((response) => {
          if (response.check) {
            this.pageData.rows = response.data.items.data;
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };
            this.pageData.isLoading = false;
          }
        });

    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`${this.pageData.entityName}/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {

    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>

<style lang="scss">
.activeTab {
  background: transparent !important;
  border-bottom: 2px solid #2d68b5 !important;
}
</style>
