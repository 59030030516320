<template>
  <section>
    <v-container fluid>
      <!-- Form -->
      <v-form ref="form" v-model="valid">
        <v-row justify="center" v-if="pageData.isLoading">
          <v-col cols="auto" class="my-15">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t("Loading data") }}</p>
          </v-col>
        </v-row>
        <v-card elevation="0" class="pa-5 shadow rounded" v-if="!pageData.isLoading">
          <v-row justify="center">
            <GenericInput type="autocomplete" v-if="!selected" :lookups="customers" selected_label="name_ar"
              selected_prop="id" :value="form.customer_id" @input="form.customer_id = $event" label="select customer"
              :loading="pageData.isLoading" :cols="[12, 12, 10]" :hideDetails="true" :disabled="customers.length == 0"
              :required="true"></GenericInput>
          </v-row>
          <span v-if="form.customer_id && selectedCustomer && !selected">
            <v-simple-table class="my-8 hoverRow">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="(header, index) in pageData.tableHeader" :key="index">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in selectedCustomer.contracts" :key="index" class="cursor_pointer"
                    @click="selectedContract(row)">
                    <td v-for="(header, y) in pageData.tableHeader" :key="y">
                      <span v-if="header.value == 'services'" class="d-flex justify-center text-uppercase">
                        <div v-for="(service, z) in row.services" :key="z">
                          {{ service.name }}
                          <span v-if="row.services.length !== z + 1">,</span>
                        </div>
                      </span>
                      <span v-else>{{ row[header.value] }}</span>
                    </td>
                  </tr>
                  <tr v-if="selectedCustomer.contracts.length == 0">
                    <th colspan="6" class="py-3" style="border : 0">
                      <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('no contracts') }}
                      </v-alert>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </span>
          <v-row v-if="form.customer_id && selectedCustomer && selected">
            <v-col cols="12">
              <div class="subtitle-1 font-weight-bold">{{ $t("customer data") }}</div>
            </v-col>
            <GenericInput type="text" :value="selected.customer_name_en" @input="selected.customer_name_en = $event"
              label="name_en" :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="text" :value="selected.customer_name_ar" @input="selected.customer_name_ar = $event"
              label="name_ar" :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="number" :value="selected.commercial_registration_no"
              @input="selected.commercial_registration_no = $event" label="commercial registration no"
              :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="number" :value="selected.tax_registration_no"
              @input="selected.tax_registration_no = $event" label="tax registration no" :loading="pageData.isLoading"
              :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="mobile" :value="selected.phone" @input="selected.phone = $event" label="phone"
              :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="email" :value="selected.email" @input="selected.email = $event" label="email"
              :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
            <GenericInput type="textarea" :value="selected.address" @input="selected.address = $event" label="address"
              :loading="pageData.isLoading" :cols="[12, 12, 12]" :readonly="true" />
            <GenericInput type="file" :value="selected.image" @input="selected.image = $event" label="logo image"
              :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" :disabled="true" />
            <GenericInput type="file" :value="selected.invoice_image" @input="selected.invoice_image = $event"
              label="invoice image" :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true"
              :disabled="true" />
            <GenericInput type="number" :value="selected.amount" @input="selected.amount = $event" label="amount"
              :loading="pageData.isLoading" :cols="[12, 6, 4]" :readonly="true" />
          </v-row>
        </v-card>
        <v-card elevation="0" class="pa-5 my-8 shadow rounded"
          v-if="form.customer_id && selectedCustomer && selected && !pageData.isLoading">
          <v-col cols="12" class="mb-8">
            <div class="subtitle-1 font-weight-bold">{{ $t("contract details") }}</div>
          </v-col>
          <v-row>
            <!-- <GenericInput
              type="number"
              v-for="(service, index) in selected.services"
              :key="index"
              :value="service.price"
              @input="service.price = $event"
              :label="service.name"
              :loading="pageData.isLoading"
              :cols="[12, 4, 3]"
            />-->
            <v-col cols="12" md="3" class="py-0" v-for="(service, index) in selected.services" :key="index">
              <v-text-field v-model="service.price" outlined :label="service.name" :loading="pageData.isLoading"
                rounded-md dense @input="() => {checkPrice(); checkDiscount(service.price, service.service_discount)}" type="number" />

              <v-text-field v-model="service.service_discount" outlined :label="'قيمه الخصم علي ' + service.name"
                :loading="pageData.isLoading" rounded-md
                :error="(Number(service.price) < Number(service.service_discount))" dense
                @input="checkDiscount(service.price, service.service_discount)" type="number" />

            </v-col>
            <v-col cols="12" class="pt-0 d-flex justify-end">
              <v-alert dense height="40" class="mx-2" type="error" outlined v-if="disc == false">
                يجب ان يكون سعر الخصم اقل من سعر الخدمة
              </v-alert>
              <v-alert dense height="40" type="info" outlined v-if="totalPrice != selected.amount">
                يجب ان يكون اجمالي الخدمات
                <strong>{{ $global.DecimalFormatter(Number(selected.amount)) }}</strong>
              </v-alert>
              <v-btn class="mx-2" height="40" depressed color="primary" :disabled="(!(pricingValid))"
                :loading="pageData.isLoading" @click="save">{{ $t('save invoice') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" class="pa-5 mt-5 shadow rounded" v-if="pageData.isEdit == true">
          <div class="subtitle-1 mb-3 font-weight-bold text--disabled">{{ $t("contract status") }}</div>
          <GenericInput type="select"
            :lookups="[{ id: 3, name: $i18n.t('accepted') }, { id: 8, name: $i18n.t('rejected by customer') }]"
            selected_label="name" selected_prop="id" :value="form.contract_status"
            @input="form.contract_status = $event" label="select status" :multi="false" :loading="pageData.isLoading"
            :cols="[12, 12, 12]" :required="true"></GenericInput>
        </v-card>
      </v-form>
      <!--  End Of Form -->

      <!-- Contract -->

      <!--  End Of Contract -->
    </v-container>
    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
      :item="form.name_ar ? ' ' + form.name_ar : null" :backValueMethod="deleteMethod"></DeleteConfirmation>
  </section>
</template>

<script>
import GenericInput from "../../components/GenericInput.vue"
import DeleteConfirmation from "../../components/modals/DeleteConfirmation.vue";
export default {
  name: "InvoiceControl",

  data: () => ({
    pageData: {
      url: "/invoices",
      controlRoute: "invoice",
      entityName: "services_invoices",
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
      tableHeader: [],
      options: {},
      rows: []
    },
    valid: false,
    deleteDialog: false,
    discountValid: true,
    services: [],
    customers: [],
    selected: null,
    totalPrice: 0,
    form: {
      customer_id: null,
      services: null,
    },

  }),
  components: {
    GenericInput,
    DeleteConfirmation,
  },
  computed: {
    pricingValid(){
      if ((Number(this.totalPrice) == Number(this.selected.amount)) && this.disc ==true ) {
         return true
      }else{
        return false
      }
    },
    disc(){
     return this.selected.services.every(service => {
        if(Number(service.price)  < Number(service.service_discount) ){
          return false
        }else{
          return true
        }
      })
    },
    selectedCustomer() {
      if (this.form.customer_id) {
        return this.$global.FilterArrayOfObject(this.customers, 'id', this.form.customer_id)[0]
      } else {
        return null
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  watch: {
    selected() {
      this.selected.services.forEach(service => {
        service.price = service.price || null
      });
    }
  },
  methods: {
    checkPrice() {
      this.totalPrice = 0
      this.selected.services.forEach(service => {
        this.totalPrice = Number(this.totalPrice) + Number(service.price)
      });
    },
    checkDiscount(price, discount) {
      if (Number(price) < Number(discount)) {
        this.discountValid = false;
      } else {
        this.discountValid = true;
      }
    },
    pageMainData() {
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t('contract number'),
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t('branch name'),
          sortable: true,
          value: "branch_name",
        },
        {
          text: this.$i18n.t("services"),
          sortable: true,
          value: "services",
        },
        {
          text: this.$i18n.t("total without tax"),
          sortable: true,
          value: "amount",
        },
        {
          text: this.$i18n.t("duration"),
          sortable: true,
          value: "duration",
        },
        {
          text: this.$i18n.t("start_date"),
          sortable: true,
          value: "start_date",
        },
        {
          text: this.$i18n.t("expiry_date"),
          sortable: true,
          value: "expiry_date",
        },
        // { text: this.$i18n.t("Settings"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: false,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      if (this.$route.params.id) {
        this.pageData.isEdit = true;
        this.pageData.editIsLoading = true;
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.entityName}/${this.$route.params.id}`).then((response) => {

          if (response.check) {
            this.form = response.data.invoice;
            this.services = response.data.services;
            this.customers.push(response.data.invoice.customer)
            this.form.services = response.data.invoice.services.split(',').map(i => Number(i));
            this.selectedCustomer = response.data.invoice.customer;
            console.log('this.form', this.form);

            this.$refs.form.validate();
          }

          this.pageData.editIsLoading = false;
          this.pageData.isLoading = false;
        })
      }
      else {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.entityName}/create`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.services = response.data.services;
            this.customers = response.data.customers;
          }
        })
      }

    },

    selectedContract(row) {
      this.selected = row
    },
    save() {
      this.selected.services.forEach(service => {
        service.price = Number(service.price);
        service.service_discount = Number(service.service_discount);
        delete service.pivot;
        delete service.updated_at;
        delete service.created_at;
      });
      let requestBody = {
        contract_id: this.selected.id,
        services: this.selected.services
      }
      this.pageData.isLoading = true;
      // console.log('requestBody', requestBody);
      this.$api.POST_METHOD(`${this.pageData.entityName}`, requestBody).then((response) => {
        this.pageData.isLoading = false;
        if (response.check) {
          this.$router.push(this.pageData.url)
        }
      })
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD(`${this.pageData.entityName}/${this.form.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url);
          this.pageData.isLoading = false
        })
      }
    },
    restoreMethod(id) {
      this.pageData.isLoading = true
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${id}`, null).then((response) => {
        if (response.check) {
          this.$router.push(this.pageData.url);
        }
        this.pageData.isLoading = false
      });
    },

  },
};
</script>
