<template>
  <div>
    <v-main class="light">
      <v-container fluid  v-if="$store.state.isAuth == true">
        <v-row>

          <v-app-bar
            :color="$store.state.Settings.darkTheme == false ? '#fff' : ''"
            class="shadow"
            app
          >
            <Appbar />
          </v-app-bar>
          <v-container fluid class="pa-0 ">
            <router-view></router-view>
          </v-container>
        </v-row>
        <v-footer
          outlined
          min-width="100vw"
          color="transparent"
          fixed
          padless
          v-if="networkConnection == false"
        >
          <v-alert
            color="grey darken-1"
            icon="mdi-wifi-off"
            colored-border
            dense
            elevation="0"
            class="my-0 mi-start-auto"
            rounded="0"
          >
            <p class="text-center text--secondary font-weight-bold ma-0" style="width: 100%;">
              {{ $t("No Internet Connection") }}
              <!-- <v-icon class="mx-2">mdi-wifi-off</v-icon> -->
            </p>
          </v-alert>
        </v-footer>
      </v-container>
    </v-main>

    <!-- <v-footer app></v-footer> -->
  </div>
</template>


<script>
import Appbar from "../components/ui/Appbar.vue";

export default {
  name: "Dashboard",
  components: {
    Appbar,
  },
  mounted() {
    this.$store.dispatch("checkAuth");
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET()
    }, 2000);
  },
   data: () => ({
      mini: false,
      darkTheme: false,
      networkConnection: true
   }),
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
