// import ar from 'vuetify/lib/locale/ar'

export default {
  // ...ar,

  darkMode: "الوضع الليلي",
  english: "English",
  profile: "الصفحه الشخصية",
  home: "الرئيسية",
  sales: "مبيعات",
  usedInvoice: "مشتريات كسر",
  startShift: "بدء الدوام",
  endShift: "إنهاء الدوام",
  branch: "الفرع",
  changeBranch: "تغيير الفرع",
  finalCash: "الاجمالي النهائي للكاش",
  caliber: "عيار",
  Caliber: "عيار",
  salesInvoices: "فاتوره مبيعات",
  usedInvoices: "مشتريات كسر",
  price: "سعر",
  weight: "وزن",
  total: "اجمالي",
  lastUpdate: "اخر تحديث",
  code: "الكود",
  customerName: "اسم العميل",
  print: "طباعة",
  customerMobile: "جوال العميل",
  vat: "الضريبه",
  grandTotal: "الاجمالي النهائي",
  view: "اظهار",
  from: "من",
  to: "الي",
  createSalesInvoice: "انشاء فاتوره مبيعات",
  delete: "مسح",
  item: "صنف",
  create: "اضافة",
  countPices: "عدد القطع",
  save: "حفظ",
  clear: "مسح",
  gold: "ذهب",
  countSalesInvoices: "عدد فواتير المبيعات",
  countUsedInvoices: "عدد مشتريات الكسر",
  totalSalesCash: "اجمالي مبيعات الكاش",
  totalSalesMachine: "اجمالي مبيعات الماكينة",
  totalUsedCash: "اجمالي مشتريات الكاش",
  finalTotalCash: "الإجمالي النهائي للكاش  ",
  remining: "المتبقي",
  totalPaid: "ادجمالي المدفوع",
  noMachine: "لا مكينة",
  noAgent: "لا عميل",
  payCash: "الدفع كاش",
  payBank: "الدفع من البنك",
  payCashBank: "الدفع من البنك و كاش",
  deletePaid: "مسح المدفوع",
  saveInvoice: "حفظ المدفوع",
  ok: "تم",
  login: "تسجيل الدخول",
  logintoQaratPOSSystem: "تسجيل الدخول لنظام قيراط للبيع",
  logout: "تسجيل الخروج",
  userName: "اسم المستخدم",
  password: "كلمة السر",
  money: "النقود",
  ryal: "ريال سعودي",
  invoiceList: "قائمة الفواتير",
  add: "اضافة",
  purchaseInvoice: " فاتورة مشتريات",
  salesInvoice: "فاتورة مبيعات",
  createaPurchaseInvoice: "انشاء فاتورة مشتريات",
  pleaseEnterWeight: "برجاء إدخال الوزن",
  pleaseEnterThePrice: "برجاء ادخال السعر",
  chooseTheProductToSell: "أختر المنتج المطلوب بيعه",
  notes: "ملاحظات",
  PleaseEnterThecustomerName: "برجاء ادخال اسم العميل",
  idNumber: "رقم الهوية",
  mobileNumber: "رقم الجوال",
  modify: "تعديل",
  gram: "جرام",
  totalweight: "إجمالي الوزن",
  next: "التالي",
  prev: "السابق",
  notAvail: "غير متاح",
  changeSellar: "تغيير البائع ",
  changeMachine: "تغيير الماكينة  ",
  pleaseEnterTheAmountPaidByTheNetwork :"برجاء ادخال المبلغ المدفوع بالشبكة",
  theamountrequired:"المبلغ المطلوب ",
  noItemsAdded :"لا يوجد اصناف مضافة",
  noInvoicesAdded :"لا يوجد فواتير مضافة",
  priceOriginal:"السعر الاساسي",
  lessPrice:"اقل سعر البيع",
  sellPrice:"سعر البيع",
  qty:"عدد القطع",
  1: "١",
  2: "٢",
  3: "٣",
  4: "٤",
  5: "٥",
  6: "٦",
  7: "٧",
  8: "٨",
  9: "٩",
  0: "٠",
  goldPrice: "سعر الذهب",
  "Username is required" : "اسم المستخدم مطلوب",
  "Password is required" : "كلمة المرور مطلوبة",
  "Password must be greater than 5 characters" : "يجب أن تكون كلمة المرور أكبر من 5 أحرف",
  "Save":"حفظ",
  "Ok":"حسنا",
  "Delete":"حذف",
  "Remove":"إزالة",
  "Clear":"تفريغ",
  "Search":"بحث",
  "Login":"دخول",
  "Logout":"تسجيل الخروج",
  "Log out": "تسجيل الخروج",
  "Email":"البريد الإلكتروني",
  "Email Address":"عنوان البريد الإلكتروني",
  "Password":"كلمة المرور",
  "Forget Password":"نسيت كلمة المرور",
  "Remember me":"تذكرني",
  "Successfully":"بنجاح",
  "Error":"خطأ",
  "Excel":"اكسيل",
  "Import":"استيراد",
  "Export":"تصدير",
  "Category":"الفئة",
  "Categories":"الفئات",
  "Invoices":"الفواتير",
  "Invoice":"الفاتورة",
  "Sales invoice":"فاتورة المبيعات",
  "Sales invoices":"فواتير المبيعات",
  "Total":"المجموع",
  "Total Price":"السعر الإجمالي",
  "Price":"السعر",
  "Purchase invoice":"فاتورة الشراء",
  "Purchase invoices":"فواتير الشراء",
  "Welcome":"اهلا بك",
  "Welcome Back":"مرحبًا بعودتك",
  "I Don't have an account": "ليس لدي حساب",
  "or": "او",
  "OR": "او",
  "Login with": "تسجيل الدخول باستخدام",
  "Back": "رجوع",
  "Skip": "تخطي",
  "Name": "الاسم",
  "name": "الاسم",
  "Send message": "إرسال رسالة",
  "Code": "الرمز",
  "Enter New Password": "خل كلمة مرور جديدة",
  "I Have an account": "لدي حساب",
  "Good Morning": "صباح الخير",
  "Good Afternoon": "مساء الخير",
  "Good Evening": "مساء الخير",
  "Good Noon": "ظهيرة سعيدة",
  "Date": "التاريخ",
  "date": "التاريخ",
  "Tax": "الضريبة",
  "tax": "الضريبة",
  "taxes": "الضرائب",
  "Taxes": "الضرائب",
  "Confirm": "تأكيد",
  "Services": "الخدمات",
  "services": "الخدمات",
  "service": "الخدمة",
  "Service": "الخدمة",
  "Account": "الحساب",
  "Edit": "تعديل",
  "Settings": "الإعدادات",
  "Language": "اللغة",
  "Canceled": "ملغاة",
  "Address": "عنوان",
  "Phone": "الهاتف",
  "Type": "النوع",
  "Status": "الحالة",
  "Cancel": "إلغاء",
  "Sorry": "آسف",
  "Done": "تم",
  "Rquired": "مطلوب",
  "Continue": "متابعة",
  "Please enter email address": "الرجاء إدخال عنوان البريد الإلكتروني",
  "Please enter valid email address": "الرجاء إدخال عنوان بريد إلكتروني صالح",
  "Please enter mobile number": "الرجاء إدخال رقم الجوال",
  "Please enter valid mobile number": "الرجاء إدخال رقم الهاتف المتحرك بشكل صحيح",
  "Please enter valid number": "الرجاء إدخال رقم صحيح",
  "Please enter your password": "الرجاء إدخال كلمة المرور الخاصة بك",
  "Minimum length is 8 characters": "الحد الأدنى للطول 8 أحرف",
  "Maximum length is 12 characters": "الحد الأقصى للطول هو 12 حرفًا",
  "Password not matched": "كلمة المرور غير متطابقة",
  "Product": "المنتج",
  "product": "المنتج",
  "Products": "المنتجات",
  "products": "المنتجات",
  "Product summary": "ملخص المنتج",
  "Product description": "وصف المنتج",
  "Pages":"الصفحات",
  "Page":"الصفحة",
  "Next":"التالي",
  "Prev":"السابق",
  "Vat":"ضريبة القيمة المضافة",
  "Print":"طباعة",
  "Action":"الإجراء",
  "Actions":"الإجراءات",
  "Home":"الرئيسية",
  "Branch":"الفرع",
  "Branches":"الفروع",
  "branches":"الفروع",
  "lorem title 1":"لوريم إيبسوم",
  "lorem title 2":"مثال علي عنوان",
  "lorem title 3":"المحتوى النصي",
  "lorem title 4":"نموذج من النص",
  "lorem title 5":"كلام افتراضي",
  "lorem body 1":"هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
  "lorem body 2":"القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف.",
  "lorem body 3":"خلافاَ للإعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد",
  "lorem body 4":" انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق لبلاستيكية تحوي مقاطع من هذا النص وعاد لينتشر مرة أخرى مؤخراَ",
  "lorem body 5":"ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
  "Copyright":" جميع حقوق النشر محفوظة ©",
  "Privacy & terms":"الخصوصية والبنود",
  "Privacy Policy":"سياسة خاصة",
  "Terms & Conditions":"الشروط والأحكام",
  "FAQ":"التعليمات",
  "About US":"نبذة عنا",
  "Contact US":"اتصل بنا",
  "Field is required":"حقل الادخال هذا مطلوب",
  "Invoice Created Successfully" : "الفاتورة تم إنشاؤها بنجاح",
  "Logged in successfully":"تم تسجيل الدخول بنجاح",
  "Please Start Shift":"الرجاء بدء الدوام",
  "Wrong Username or Password":"اسم مستخدم أو كلمة مرور خاطئة",
  "No Results":"لا يوجد نتيجة",
  "here":"هنا",
  "Welcome to" : "مرحباً بك في نظام",
  "Qarat System" : "قيراط الالكتروني",
  "favourites":"المفضلة",
  "gold price today":"سعر الذهب اليوم",
  "more":"المزيد",
  "Total gold sales":"إجمالي مبيعات الذهب",
  "gold sales":"مبيعات الذهب",
  "gold purchases":"مشتريات الذهب",
  "number of orders":"عدد الطلبات",
  "order":"طلب",
  "Users":"المستخدمين",
  "Loading... Please wait":"جاري التحميل... انتظر من فضلك",
  "add this page to favourites" : "اضف هذه الصفحة الي المفضلة",
  "in your favourites" : "في المفضلة",
  "add new user":"اضف مستخدم جديد",
  "fullname" :"الأسم كامل",
  "job":"وظيفة",
  "Fiscal year":"السنه المالية",
  "Daily restrictions":"قيود يومية",
  "Daily restriction":"قيد يومي",
  "add Daily restriction":"اضف قيد يومي",
  "Transactions":"الحركات",
  "Accounting":"الحسابات",
  "Analytical statement":"بيان تحليلي",
  "Trial Balance":"ميزان المراجعة",
  "Accounts Menu":"قائمة الحسابات",
  "Cost Center":"مركز التكلفة",
  "Cost Center Code":" كود مركز التكلفة ",
  "Bulk Delete Rows":"حذف صفوف مجمعة",
  "Delete Selected rows":"حذف الصفوف المحددة",
  "restriction number":"رقم القيد",
  "restriction description":"وصف القيد",
  "restriction date":"تاريخ القيد",
  "month":"الشهر",
  "daily":"اليومية",
  "created_at":"تاريخ الأضافة",
  "debtor":"المدين",
  "creditor":"الدائن",
  "description_en":"الوصف بالأنجليزية",
  "description_ar":"الوصف بالعربي",
  "transaction_description_en":"وصف الحركة بالأنجليزية",
  "transaction_description_ar":"وصف الحركة بالعربي",
  "restriction_en":"القيد بالأنجليزية",
  "restriction_ar":"القيد بالعربي",
  "new line":"اضف سطر",
  "difference":"الفرق",
  "invoice date":"تاريخ الفاتورة",
  "account code": "كود الحساب",
  "account name": "اسم الحساب",
  "account": "الحساب",
  "users permissions":"صلاحيات المستخدمين",
  "search in all users and control their permissions":"البحث في جميع المستخدمين والتحكم في أذوناتهم",
  "all":"الكل",
  "inventory":"المخزن",
  "delivery":"توصيل",
  "security":"الأمن",
  "force delete":"مسح نهائي",
  "Last Activity":"الأنشطة الأخيرة",
  "Users Permissions":"صلاحيات المستخدمين",
  "codes":"اكواد",
  "reports":"التقارير",
  "transactions":"المعاملات",
  "":"",
};
