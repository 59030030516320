<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card relative>
        <v-card-title class="text-h5 font-weight-bold">
          <img src="../../assets/img/svg/sendcontract.svg" class="mx-2" alt="contract" />
          {{ $t('send') }} {{$t(dialogType)}}

          <v-btn
                  absolute
                  class="mt-2"
                  :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
                  small
                  fab
                  color="grey lighten-1"
                  text
                  @click="closeDialog"
               >
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
        </v-card-title>

        <v-col cols="12" class="py-4">
          <v-row justify="end">
            <GenericInput
              type="radiogroup"
              :lookups="[{ name: $i18n.t('send through email'), id: 1 }, { name: $i18n.t('send through SMS'), id: 2 }, { name: $i18n.t('send through email & SMS'), id: 3}]"
              selected_label="name"
              selected_prop="id"
              :value="type"
              @input="type = $event"
              label=" "
              :isLoading="false"
              :cols="[12, 12, 12]"
            ></GenericInput>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="returnData"
                :disabled="!type"
                class="shadow"
                style="width: 100%; text-align: start"
              >{{ $t('send') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import GenericInput from "../GenericInput.vue";
export default {
  name: "SendContract",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    dialogType: { default: 'contract' },
  },
  components: {
    GenericInput
  },
  computed: {

  },
  data() {
    return {
      type: null
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.backValueMethod(this.type,false);
    },
    returnData() {
      this.dialog = false
      this.backValueMethod(this.type,true);
      this.type = null
    },
    print() {
      this.$api.DOWNLOAD_METHOD(`pdf/invoice${this.link}.pdf`).then((response) => {
        if (response.check) {
          console.log('download');
        }
        this.dialog = false
      })

    }
  },
};
</script>
