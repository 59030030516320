import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueDummy from "./plugins/VueDummy";
import axios from "./plugins/custom-axios";
import i18n from "./plugins/i18n";
import "@/plugins/apexcharts";
import "@/plugins/vuePrint";
import VueSweetalert2 from "./plugins/sweetalert";
import "./registerServiceWorker";
import apiPlugin from "./plugins/api";
import vueEditor from "./plugins/vueEditor"; 
import vue2Dropzone from "./plugins/vue2Dropzone";
import global from "./plugins/global";
import VueLazyload from "./plugins/lazyload";
Vue.prototype.$http = axios;
Vue.use(apiPlugin);
Vue.use(global);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  vueDummy,
  // axios,
  i18n,
  vueEditor,
  vue2Dropzone,
  VueSweetalert2,
  VueLazyload,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
