<template>
  <v-col cols="12" sm="12" class="pa-0">
    <!-- 
         fixed-header
        height="60vh"
    -->
    <v-data-table
      :headers="header"
      :items="isLoading == false ? data : []"
      disable-pagination
      calculate-widths
      :hide-default-header="isLoading"
      :loading="isLoading"
      :loading-text="$i18n.t('Loading... Please wait')"
      :no-data-text="$i18n.t('No data available')"
      :sort-by="options.sortBy"
      hide-default-footer
      disable-sort
      class="elevation-0 backgroundW"
      :class="options.handleClickRow ? 'hoverRow' : ''"
      :search="searchValue ? searchValue : search"
      :show-select="options.selectRow"
      v-model="selected"
      @click:row="handleClickRow"
    >
      <!-- eslint-disable-next-line  -->
      <template v-slot:body.append>
        <tr v-if="pageData.footerRow" class="light">
          <td
            v-for="(footer, i) in pageData.footerRow"
            :key="i"
            class="font-weight-bold light"
            :class="footer.class"
            :colspan="footer.colspan"
          >{{ footer.value }}</td>
        </tr>
      </template>

      <!-- eslint-disable-next-line  -->
      <template v-slot:item.actions="{ item }" color="info">
        <div class="d-flex align-center justify-center">
          <v-switch
            v-if="options.switch == true"
            inset
            @mouseup="toggleActiveMethod(item)"
            color="indigo darken-2"
            hide-details
            dense
            class="my-auto"
            v-model="item.active"
          ></v-switch>

          <v-tooltip bottom v-if="options.sendContract" color="light">
            <template v-slot:activator="{ on, attrs }">
              <img
                class="mx-2 cursor_pointer"
                style="height: 13px;"
                v-bind="attrs"
                v-on="on"
                @click="sendContract(item)"
                src="../assets/img/svg/send-icon.svg"
                alt="qarat erp"
              />
            </template>
            <span class="primary--text">{{ $t('send invoice') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="options.renew">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-pill"
                color="success"
                v-bind="attrs"
                @click="editValueMethod(item)"
                v-on="on"
                depressed
                small
              >{{ $t('renew') }}</v-btn>
            </template>
            <span>{{ $t('renew') }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="options.print" color="success">
            <template v-slot:activator="{ on, attrs }">
              <img
                class="mx-2 cursor_pointer"
                style="height: 18px;"
                v-bind="attrs"
                v-on="on"
                @click="print(item)"
                src="../assets/img/svg/print-icon.svg"
                alt="qarat erp"
              />
              <!-- <v-icon v-bind="attrs" v-on="on" class="mx-2" color="info" small>mdi-printer</v-icon> -->
            </template>
            <span>{{ $t('print') }}</span>
          </v-tooltip>
          <div v-if="isLoading == true">
            <v-icon class="mdi-spin mdi-dark">mdi-loading</v-icon>
          </div>
          <v-tooltip bottom v-if="options.edit && isLoading == false" color="primary">
            <template v-slot:activator="{ on, attrs }">
              <img
                class="mx-2 cursor_pointer"
                style="height: 18px;"
                v-bind="attrs"
                v-on="on"
                @click="editValueMethod(item)"
                src="../assets/img/svg/edit-icon.svg"
                alt="qarat erp"
              />
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="options.delete && pageData.isTrashed == 1 && isLoading == false"
            color="error"
          >
            <template v-slot:activator="{ on, attrs }">
              <img
                class="mx-2 cursor_pointer"
                style="height: 18px;"
                v-bind="attrs"
                v-on="on"
                @click="dialogDeleteItem = item, deleteDialog = true"
                src="../assets/img/svg/delete-icon.svg"
                alt="qarat erp"
              />
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="options.restore && pageData.isTrashed == 0 && isLoading == false"
            color="light"
          >
            <template v-slot:activator="{ on, attrs }">
              <img
                class="mx-2 cursor_pointer"
                style="height: 20px;"
                v-bind="attrs"
                v-on="on"
                @click="restoreValueMethod(item)"
                src="../assets/img/svg/restore-icon.svg"
                alt="qarat erp"
              />
            </template>
            <span class="primary--text">{{ $t('restore') }}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- eslint-disable-next-line  -->
      <template v-slot:item.image="{ item }">
        <v-avatar size="auto" tile>
          <img
            v-if="item.image"
            style="max-height: 35px;"
            class="ma-auto d-block"
            v-lazy="$api.serverUrl + item.image"
            alt="erp"
          />
          <v-icon v-if="!item.image">mdi-file-image-outline</v-icon>
        </v-avatar>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.main_image="{ item }">
        <v-avatar size="auto" tile>
          <img
            v-if="item.main_image"
            style="max-height: 35px;"
            v-lazy="$api.serverUrl + item.main_image"
            alt="erp"
          />
          <v-icon v-if="!item.main_image">mdi-file-image-outline</v-icon>
        </v-avatar>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.add_date="{ item }">
        <p>
          {{
            new Date(item.add_date).toLocaleDateString(
              `${$vuetify.rtl == true ? "ar-EG" : "en-UK"}`,
              {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }
            )
          }} ( {{ new Date(item.add_date).toLocaleTimeString(`${$vuetify.rtl == true ? "ar-EG" : "en-UK"}`) }} )
        </p>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.expiry_date="{ item }">
        <p>
          {{
            new Date(item.expiry_date).toLocaleDateString(
              `${$vuetify.rtl == true ? "ar-EG" : "en-UK"}`,
              {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }
            )
          }} ( {{ new Date(item.expiry_date).toLocaleTimeString(`${$vuetify.rtl == true ? "ar-EG" : "en-UK"}`) }} )
        </p>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.send_contract="{ item }">
        <v-btn
          text
          color="primary"
          class="text-decoration-underline"
          small
          :disabled="!(item.contract_status == 1)"
          @click="sendContract(item)"
        >{{ $t('send to customer') }}</v-btn>
      </template>
      <!-- eslint-disable-next-line  -->
      <template
        v-slot:item.tax_amount="{ item }"
      >{{ $global.DecimalFormatter(Number(item.tax_amount).toFixed(2)) }}</template>
      <!-- eslint-disable-next-line  -->
      <template
        v-slot:item.total="{ item }"
      >{{ $global.DecimalFormatter(Number(item.total).toFixed(2)) }}</template>
      <!-- eslint-disable-next-line  -->
      <template
        v-slot:item.total_without_tax="{ item }"
      >{{ $global.DecimalFormatter(Number(item.total_without_tax).toFixed(2)) }}</template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.contract_active="{ item }">
        <div class="d-flex">
          <v-switch
            inset
            @mouseup="toggleActiveMethod(item)"
            color="primary"
            hide-details
            dense
            class="ma-auto d-block"
            v-model="item.contract_active"
          ></v-switch>
        </div>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.contract_status="{ item }">
        <v-chip v-if="item.contract_status == 0" color="error" outlined small>{{ $t("deleted") }}</v-chip>
        <v-chip
          v-if="item.contract_status == 1"
          color="success"
          small
          text-color="white"
        >{{ $t("new") }}</v-chip>
        <v-chip
          v-if="item.contract_status == 2"
          color="warning"
          small
          text-color="white"
        >{{ $t("pending") }}</v-chip>
        <v-chip
          v-if="item.contract_status == 3"
          color="black"
          small
          text-color="white"
        >{{ $t("accepted") }}</v-chip>
        <v-chip
          v-if="item.contract_status == 4"
          color="error"
          small
          text-color="white"
        >{{ $t("rejected") }}</v-chip>
        <!-- <v-chip
          v-if="item.contract_status == 5"
          color="primary"
          outlined
          small
        >{{ $t("on activate") }}</v-chip>-->
        <v-chip
          v-if="item.contract_status == 5"
          color="primary"
          small
          text-color="white"
        >{{ $t("expired") }}</v-chip>
        <!-- <v-chip v-if="item.contract_status == 7" color="warning" outlined small>{{ $t("finished") }}</v-chip> -->
        <!-- <v-chip
          v-if="item.contract_status == 8"
          color="warning"
          outlined
          small
        >{{ $t("rejected by customer") }}</v-chip>-->
      </template>
    </v-data-table>
    <DeleteConfirmation
      v-if="deleteDialog == true"
      :dialog="deleteDialog"
      :item="dialogDeleteItem.name ? dialogDeleteItem.name : null"
      :backValueMethod="deleteValue"
    ></DeleteConfirmation>
  </v-col>
</template>




<style scoped lang="scss">
#printInvoice {
  @media screen {
    display: none;
  }
  @media print {
    display: block;
  }
}
thead {
  tr {
    &:nth-of-type(1) {
      background-color: #f2f2f2 !important;
      &:hover {
        background-color: #f2f2f2 !important;
      }
    }
  }
}
tr {
  &:hover {
    background: transparent !important ;
  }
}
td,
th {
  border-bottom: 0 !important;
}
</style>



<script>
import DeleteConfirmation from "./modals/DeleteConfirmation.vue";
export default {
  name: "DataTable",
  computed: {

  },
  components: {
    DeleteConfirmation
  },
  methods: {
    handleClickRow(row) {
      // console.log(row);
      this.backValueMethod(row);
    },
    deleteValue(status) {
      this.deleteDialog = false;
      if (status == true) {
        if (this.deleteProperty) {
          this.deleteValueMethod(this.dialogDeleteItem[this.deleteProperty])
        } else {

          this.deleteValueMethod(this.dialogDeleteItem.id)
        }
      }
    }
  },
  props: {
    backValueMethod: { type: Function },
    editValueMethod: { type: Function },
    print: { type: Function },
    deleteValueMethod: { type: Function },
    restoreValueMethod: { type: Function },
    toggleActiveMethod: { type: Function },
    sendContract: { type: Function },
    pageData: { default: {} },
    isLoading: { default: false },
    value: { default: "" },
    data: { default: "" },
    header: { default: "" },
    options: { default: "" },
    searchValue: { default: "" },
    deleteProperty: { default: null },
  },
  data() {
    return {
      search: '',
      selected: [],
      deleteDialog: false,
      dialogDeleteItem: null,
    };
  },
};
</script>
