import Vue from "vue";
import Vuex from "vuex";

import Settings from "./Settings";
import router from "../router";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber  
    splashScreen: false,
    sidebarIsOpen: false,
    endpointURL : "https://contract-api.cloudsecretsksa.com/api/v1/",
    apiAccess :   "KWoIUM5UAXLVP3gWc904mOsYy84LWYTKYeUqdgnyCuzuSrtbLuqHeqEZYvd1hF67NxrrFt1x6Nyh9xNdZsSG1pHolQroT0myTMfSi1CBbtbLuqHeqEZYvd1hF67Nxr9fZDrc15L0GqT8LBsNCSYKaWTvzcukt5mvgJ3ZqC8GORaduPRP0ng3VQxkreL7kO8jBHvAnRO",
    // user
    isAuth: (localStorage.getItem("access-token")) ? true : false,
    accessToken: (localStorage.getItem("access-token")) ? (localStorage.getItem("access-token")) : null,
  },
  mutations: {

  },

  actions: {
    checkAuth({ state }) {
       if(!state.isAuth){
          router.push(`${process.env.BASE_URL}login`)
       }
    },
    checkAppVersion({ state }) {
      let localStorageVersion = localStorage.getItem("app_version");
      if (state.app_version != localStorageVersion) {
        state.isAuth = false;
        router.push("/login");
        axios.interceptors.response.use(
          (resp) => {
            if(resp.config.method == 'get'){
              localStorage.setItem("app_version", state.app_version);
              window.location.reload()
            }
            return Promise.resolve(resp)
          },
        )
      }
    },
     
  },

  modules: { Settings , },
});
