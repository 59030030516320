import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../router";
import store from "../store";
//////////////////

const endpointURL = "https://contract-api.cloudsecretsksa.com/api/v1/";
var accessToken = localStorage.getItem("access-token");
const api_token =
  "KWoIUM5UAXLVP3gWc904mOsYy84LWYTKYeUqdgnyCuzuSrtbLuqHeqEZYvd1hF67NxrrFt1x6Nyh9xNdZsSG1pHolQroT0myTMfSi1CBbtbLuqHeqEZYvd1hF67Nxr9fZDrc15L0GqT8LBsNCSYKaWTvzcukt5mvgJ3ZqC8GORaduPRP0ng3VQxkreL7kO8jBHvAnRO";
const lang = localStorage.getItem("language") ? localStorage.getItem("language") : "en";


///////// Axios Defaults Header /////////

axios.defaults.baseURL = endpointURL + lang + "/";
axios.defaults.headers.common["access-token"] = api_token;
axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;


export default {
  ///////// API Methods /////////

  async GET_METHOD(RESOURCE_NAME) {
    try {
      const response = await axios.get(RESOURCE_NAME);
      console.log("response.data", response.data);
      store.state.lastUpdate = response.data.data.lastUpdate;
      localStorage.setItem("lastUpdate", response.data.data.lastUpdate);
      return response.data;
    } catch (error) {
      console.log(error);
      this.SetError(error.response.status, error);
    }
  },
  async POST_METHOD(RESOURCE_NAME ,NEW_DATA ,TOAST = false, TOAST_MESSAGE = " ") {
    try {
      const response = await axios.post(RESOURCE_NAME, NEW_DATA);
      console.log("response.data", response.data);
      if (TOAST) {
        this.ShowAlert("success", " ", TOAST_MESSAGE);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      this.SetError(error.response.status, error);
    }
  },
  async PUT_METHOD(RESOURCE_NAME ,ID ,NEW_DATA ,TOAST = false,TOAST_MESSAGE = " ") {
    try {
      const response = await axios.put(RESOURCE_NAME + "/" + ID, NEW_DATA);
      console.log("response.data", response.data);
      if (TOAST) {
        this.ShowAlert("success", " ", TOAST_MESSAGE);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      this.SetError(error.response.status, error);
    }
  },
  async DELETE_METHOD(RESOURCE_NAME, ID, TOAST = false, TOAST_MESSAGE = " ") {
    try {
      const response = await axios.delete(RESOURCE_NAME + "/" + ID);
      console.log("response.data", response.data);
      if (TOAST) {
        this.ShowAlert("success", " ", TOAST_MESSAGE);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      this.SetError(error.response.status, error);
    }
  },

  async LOGIN_AUTH(LOGIN_DATA, TOAST = true, TOAST_MESSAGE = "Welcome Back") {
    try {
      const response = await axios.post("login", LOGIN_DATA);
      if(response.data.check == false){
        this.ShowAlert("error", "Wrong Username or Password", response.data.msg);
      }else{
        if (TOAST) {
          this.ShowAlert("success", "Logged in succefully", TOAST_MESSAGE);
        }
        this.SET_AUTH(response.data.data);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      this.SetError(error.response.status, error);
    }
  },

  ///////// Set Auth Data /////////
  SET_AUTH(AUTH_DATA) {
    console.log("AUTH_DATA", AUTH_DATA);
    localStorage.setItem("access-token", AUTH_DATA.api_token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_DATA.api_token;
    localStorage.setItem("full_name", AUTH_DATA.full_name);
    localStorage.setItem("email", AUTH_DATA.email);
    if(AUTH_DATA.user.shift){
      store.state.isAuth= true;
      axios.defaults.headers.common["currentbranch"] = AUTH_DATA.user.shift.branch_id;
      localStorage.setItem("currentbranch", AUTH_DATA.user.shift.branch_id);
      localStorage.setItem("permissions", JSON.stringify(AUTH_DATA.user.mypermissions));
      localStorage.setItem("shift", JSON.stringify(AUTH_DATA.user.shift));
      router.push('/');
     
    }else{
      this.ShowAlert("info", "Logged in succefully", "Please Start Shift");
    }
    return AUTH_DATA;
  },
  
  LOGOUT() {
    store.state.isAuth= false;
    localStorage.removeItem("access-token");
    localStorage.removeItem("currentbranch");
    localStorage.removeItem("full_name");
    localStorage.removeItem("email");
    localStorage.removeItem("permissions");
    localStorage.removeItem("shift");
    router.push("/login");
  },

  ///////// API Errors Action /////////
  SetError(STATUS, ERROR) {
    if (STATUS == 401) {
      // not auth
      router.push("/login");
    }
    if (STATUS == 404) {
      // not found
      router.push("/notfound");
    }
    if (STATUS == 500) {
      // server error
      router.push("/error500");
    } else {
      this.ShowAlert("error", "Request error", ERROR);
    }
  },

  ///////// Alert Toast /////////
  ShowAlert(TYPE, TITLE, MSG) {
    Swal.fire({
      icon: TYPE,
      title: TITLE,
      text: MSG,
      toast: true,
      timer: 3000,
      position: "top-end",
      showConfirmButton: false,
    });
  },
};
