<template>
  <v-container class="py-0">
    <v-row justify="space-between" align="center">
      <v-col cols="auto" sm="auto">
        <v-pagination
          v-model="pagination.page"
          total-visible="7"
          :disabled="isLoading"
          @input="returnActivePage"
          :length="pagination.totalPages"
          color="primary"
          
        ></v-pagination>
      </v-col>
      <v-col cols="auto" sm="auto">
        <div class="d-flex align-center">
          <small class="mx-2">{{$t('view')}}</small>
          <v-select
            style="width: 80px; height: 40px"
            class="mx-2 my-0 py-0"
            outlined
            dense
            size="small"
            v-model="limit"
            @change="backValueMethod(1,limit)"
            :items="[1,10, 15, 20, 30, 50, 100]"
          ></v-select>
            <small class="mx-2">{{$t('total')}}</small>
          {{pagination.totalRows}}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>




<style scoped lang="scss">
.v-pagination__item {
  box-shadow: none !important;
}
</style>



<script>
export default {
  name: "Pagination",
  props: {
    backValueMethod: { type: Function },
    pagination: { default: null },
    limit: { default: 10 },
    isLoading: { default: "" },
  },
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    returnActivePage(page) {
      this.backValueMethod(page , this.limit)
    }
  }
};
</script>
