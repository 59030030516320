import Vue from "vue";
import vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors';
import ar from "../assets/ar"
import en from "../assets/en"
Vue.use(vuetify);

export default new vuetify({
  rtl: localStorage.getItem("language") == "en" ? false : true,
  lang: {
    locales: { en, ar },
    current: localStorage.getItem("language") == "en" ? en: ar,
   //  current: "ar",
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: localStorage.getItem("darkTheme") == "true" ? true : false,
    customProperties: true,
    themes: {
      light: {
        primary: "#0048A5",
        secondary: "#262525",
        accent:  "#0060DB",
        error: "#FF4141",
        info: "#2196F3",
        success: "#00914a",
        warning: "#FB8C00",
        backgroundD: '#1e1e1e',
        backgroundW: '#fff',
        light: "#F6F6F6"
      },
      dark: {
        primary: "#0060DB",
        secondary: "#616161",
        accent: "#0048A5",
        error: "#FF4141",
        info: "#2196F3",
        success: "#00914a",
        warning: "#FB8C00",
        backgroundD: '#fff',
        backgroundW: '#1e1e1e',
      },
    },
  },
});
