<template>
  <v-col
    :cols="cols ? cols[0] : 12"
    :sm="cols ? cols[0] : 12"
    :md="cols ? cols[1] : 6"
    :lg="cols ? cols[2] : 4"
    class="formControl py-1 d-flex"
  >
    <!--     @blur="(e) => onClickOuter(name, input, e.target.value, type, required, e)" --->
    <!--     v-on:change="onClickOuter(name, input, $event, type, required, $event)" --->
    <div style="width: 30%" v-if="labelInline == true">
      <label v-if="label && type !== 'checkbox' && type !== 'switch' && type !== 'dropzone'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </div>
    <div :style="labelInline == true ? 'width: 70%' : 'width: 100%'">
      <!-- Text || Number -->
      <v-text-field
        v-if="type == 'text' || type == 'number'"
        v-model="input"
        :disabled="disabled"
        :readonly="readonly"
        outlined
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        :loading="isLoading"
        rounded-md
        dense
        :color="color"
        :append-icon="icon"
        :hide-details="hideDetails == true ? 'auto' : false"
        :type="type"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-text-field>

      <!-- Date -->
      <v-menu
        v-if="type == 'date'"
        v-model="datePicker"
        :close-on-content-click="false"
        :label="labelInline == true ? '' : $i18n.t(label)"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="input"
            append-icon="mdi-calendar"
            @click:append="datePicker = true"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
         
            outlined
            :rules="
              required == true
                ? $store.state.Settings.requiredRule
                : $store.state.Settings.notRequiredRule
            "
            :loading="isLoading"
            rounded-md
            :hide-details="hideDetails == true ? 'auto' : false"
            dense
          >
            <template v-slot:label>
              <label v-if="labelInline == false">
                {{ $t(label) }}
                <span class="error--text" v-if="required == true">*</span>
              </label>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          color="primary"
          :locale="$vuetify.rtl == true ? 'ar-EG' : 'en-UK'"
          v-model="input"
          @input="datePicker = false"
        ></v-date-picker>
      </v-menu>

      <!-- Textarea -->
      <v-textarea
        v-if="type == 'textarea'"
        v-model="input"
        :disabled="disabled"
        :readonly="readonly"
        outlined
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        :loading="isLoading"
        rounded-md
        :no-resize="true"
        dense
        :color="color"
        :append-icon="icon"
        :hide-details="hideDetails == true ? 'auto' : false"
        :rows="2"
        :type="type"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-textarea>
      <!-- Email-->
      <v-text-field
        v-if="type == 'email'"
        v-model="input"
        :disabled="disabled"
        :readonly="readonly"
        :loading="isLoading"
        outlined
        :rules="required == true ? $store.state.Settings.emailRule : []"
        rounded-md
        :hide-details="hideDetails == true ? 'auto' : false"
        dense
        type="email"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-text-field>
      <!-- Password-->
      <v-text-field
        v-if="type == 'password'"
        v-model="input"
        outlined
        :loading="isLoading"
        :disabled="disabled"
        :readonly="readonly"
        :rules="required == true ? $store.state.Settings.passwordRule : []"
        dense
        :color="color"
        rounded-md
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        :type="showPassword ? 'text' : 'password'"
        prepend-inner-icon="mdi-account-key"
        :hide-details="hideDetails == true ? 'auto' : false"
        @click:append="showPassword = !showPassword"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-text-field>
      <!-- Mobile-->
      <v-text-field
        v-if="type == 'mobile' || type == 'phone'"
        v-model="input"
        :loading="isLoading"
        :disabled="disabled"
        :readonly="readonly"
        outlined
        :rules="required == true ? $store.state.Settings.mobileRule : []"
        rounded-md
        :hide-details="hideDetails == true ? 'auto' : false"
        :min="0"
        dense
        :color="color"
        :append-icon="icon"
        type="tel"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-text-field>
      <!-- File -->
         <!-- @change="onImageChange($event)" -->
      <v-file-input
        v-if="type == 'file'"
        v-model="input"
        rounded-md
        :loading="isLoading"
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
     
        :multiple="multi ? true : false"
        :disabled="disabled"
        :readonly="readonly"
        outlined
        dense
        :color="color"
        :append-icon="icon"
        chips
        :hide-details="hideDetails == true ? 'auto' : false"
        small-chips
        truncate-length="20"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
        <template v-slot:selection>
          <span v-if="input">{{ $t('image loaded , you can delete or update it') }}</span>
        </template>
      </v-file-input>
      <div v-if="type == 'file' && input && input !== 'null' && multi != true"  class="shadow rounded-lg pa-5" style="border: 2px solid #f7f9fb">
        <img class="d-block ma-auto rounded-lg"  style="max-height: 500px;width: -webkit-fill-available;"  v-lazy="$api.serverUrl + input" alt="qarat" />
      </div>
      <!-- Select -->
      <v-select
        v-if="type == 'select'"
        :items="lookups ? lookups : []"
        :item-text="selected_label"
        :item-value="selected_prop"
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        v-model="input"
        :disabled="disabled"
        :readonly="readonly"
        :no-data-text="$i18n.t('no selects')"
        dense
        :color="color"
        :append-icon="icon"
        :clearable="multi ? true : false"
        :loading="isLoading"
        chips
        :hide-details="hideDetails == true ? 'auto' : false"
        small-chips
        :multiple="multi ? true : false"
        outlined
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-select>
      <!-- Autocomplete -->
      <v-autocomplete
        v-if="type == 'autocomplete' || type == 'multiselect'"
        v-model="input"
        :items="lookups ? lookups : []"
        :item-text="selected_label"
        :item-value="selected_prop"
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        :multiple="multi ? true : false"
        :no-data-text="$i18n.t('no selects')"
        outlined
        dense
        :loading="isLoading"
        :disabled="disabled"
        :readonly="readonly"
        chips
        :hide-details="hideDetails == true ? 'auto' : false"
        :clearable="multi ? true : false"
        deletable-chips
        small-chips
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-autocomplete>

      <!-- Checkbox -->
      <v-checkbox
        v-if="type == 'checkbox'"
        v-model="input"
        :disabled="disabled"
        :readonly="readonly"
        hide-details
        :color="color"
        class="ma-0"
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        :true-value="1"
        :false-value="0"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-checkbox>

      <!-- Radio Group -->
      <v-radio-group
        v-if="type == 'radiogroup'"
        dense
        class="ma-0"
        hide-details
        row
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        :disabled="disabled"
        :readonly="readonly"
        v-model="input"
      >
        <v-radio
          :color="color"
          v-for="(select, index) in lookups"
          :key="index"
          :label="select[selected_label]"
          :value="select[selected_prop]"
        ></v-radio>

        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-radio-group>

      <!-- Switch -->
      <v-switch
        v-if="type == 'switch'"
        v-model="input"
        inset
        :color="color"
        :rules="
          required == true
            ? $store.state.Settings.requiredRule
            : $store.state.Settings.notRequiredRule
        "
        hide-details
        :disabled="disabled"
        :readonly="readonly"
        class="pa-0 ma-0"
        :true-value="1"
        :false-value="0"
      >
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
      </v-switch>

      <!-- Texteditor -->
      <label v-if="labelInline == false && type == 'texteditor'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
      <vue-editor
        v-if="type == 'texteditor'"
        @blur="onClickOuter(name, input, $event, type, required, $event)"
        v-model="input"
      ></vue-editor>

      <!-- dropzone -->
      <vue-dropzone
        v-if="type == 'dropzone' && dropzoneOptions"
        :options="dropzoneOptions"
        :id="input"
        :useCustomSlot="true"
        :includeStyling="true"
        @vdropzone-file-added="onImageChange"
        @vdropzone-removed-file="onRemoveHappend"
        @vdropzone-max-files-exceeded="maxFilesReached"
        ref="myVueDropzone"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-upload-Img">
            <img src="../assets/img/svg/inbox.svg" alt="" />
          </h3>
          <h3
            class="dropzone-custom-title primary--text"
          >{{ $i18n.t("Drag and drop to upload") }} {{ $i18n.t(label) }}</h3>
          <div class="subtitle">{{ $i18n.t("...or click to select from your computer") }}</div>
        </div>
      </vue-dropzone>
    </div>
  </v-col>
</template>

<script>
import { VueEditor } from "vue2-editor";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "GenericInput",
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.dropzoneOptions = {
      url: "http://localhost",
      headers: { "My-Awesome-Header": "header value" },
      // thumbnailWidth: 250,
      clickable: true,
      maxFilesize: 2,
      addRemoveLinks: true,
      dictRemoveFile: this.$i18n.t("remove"),
      maxFiles: this.multi == true ? 10 : 1,
      autoProcessQueue: false,
    };
  },
  methods: {
    async maxFilesReached(image) {
      console.log(image);
    },
    async onImageChange(image) {
      var reader = new FileReader();
      let multiImage = this.multi;
      var base64arr = [];
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });
      if (await images) {
        if (multiImage == true) {
          base64arr.push(await images);
          this.selectedImages.push(await images);
          // console.log('aaa', this.selectedImages);
          this.$emit("input", this.selectedImages);
          this.onClickOuter(
            this.name,
            this.input,
            this.selectedImages.toString(),
            this.type,
            this.required
          );
        }
        if (multiImage == null || multiImage == false) {
          this.selectedImage = await images;
          // console.log('bbbb', this.selectedImage);
          this.$emit("input", this.selectedImage);
          this.onClickOuter(
            this.name,
            this.input,
            this.selectedImage,
            this.type,
            this.required
          );
          this.$refs.myVueDropzone.disable();
        }
      }
    },
    async onRemoveHappend(image) {
      var reader = new FileReader();
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });

      if (await images) {
        const index = this.selectedImages.indexOf(await images);
        if (index > -1 && this.multi == true) {
          this.selectedImages.splice(index, 1);
          this.$emit("input", this.selectedImages);
        }
        if (this.multi == null || this.multi == false) {
          this.selectedImage = null;
          this.$emit("input", this.selectedImage);
          this.$refs.myVueDropzone.enable();
        }
      }
    },
  },
  components: {
    // UploadImages,
    vueDropzone: vue2Dropzone,
    VueEditor,
  },
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    cols: { default: null },
    required: { default: false },
    lookups_value: { default: null },
    multi: { default: null },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    readonly: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    onClickOuter: { type: Function },
  },
  data: () => ({
    showPassword: false,
    selectedImages: [],
    selectedImage: null,
    dropzoneOptions: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePicker: false,
  }),
};
</script>

<style lang="scss">
.theme--dark .vue-dropzone{
  background: transparent !important;
      border: 2px solid #181818;
}
.formControl .dropz .formControl .dropzone {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  border: 2px dashed #c2cdda;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.formControl .dz-error-mark,
.formControl .dz-error-message {
  display: none !important;
}
.dropzone .dz-preview .dz-image img {
  margin: auto;
}
.formControl .dropzone .dz-preview:not(.dz-processing) .dz-progress {
  display: none !important;
}
.dropzone .dz-preview.dz-image-preview {
  background: transparent;
  min-width: 270px;
}
.formControl .vue-dropzone > .dz-preview .dz-remove {
  right: 25%;
  left: 25%;
  border: 0;
  background: #ff4141;
  border-radius: 5px;
  &:hover {
    text-decoration: none;
    opacity: 0.85;
  }
}
.dropzone .dz-message {
  text-align: center;
  margin: 0;
}
.formControl .dropzone-custom-title {
  margin-top: 0;
  // color: #00b782;
}

.formControl .subtitle {
  color: #314b5f;
}
.vue-dropzone > .dz-preview .dz-details {
  border-radius: 10px;
  background-color: #c2cdda;
}
.formControl .dropzone-custom-content {
  width: 100%;
  height: 100%;
  // background: #f5f6fa;
  // border: 0.5px solid #e1e1e1;
  border-radius: 5px;
  padding: 1rem 0;
  position: relative;
  .error {
    background-color: transparent !important;
    border-color: #ff4141 !important;
  }
  .plus {
    display: none;
  }
  .imgsPreview {
    display: flex;
    justify-content: center;
  }
}
</style>