<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    registration: null,
    updateExists: false,
     refreshing: false,
  }),
  created() {
    document.addEventListener('swUpdated', this.swUpdated, { once: true });
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
     navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  mounted() {
    this.$store.dispatch("checkAppVersion");
    this.$i18n.locale = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "ar";
    document.title =
      this.$i18n.t("Contracts System") + " - " + this.$i18n.t("Cloud Secrets");
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      this.$global.ShowAlert('info', this.$i18n.t('New content is available; please refresh.') );
      this.refreshApp()
    },
    swUpdateFound(event) {
      console.log('swUpdateFound', event);
      this.$global.ShowAlert('info', this.$i18n.t('New content is downloading.'))
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
};
</script>
