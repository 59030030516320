<template>
  <section>
    <v-container fluid>
      <v-card elevation="0" class="ma-0 shadow mt-5 pa-0 backgroundW rounded-lg">
        <v-row justify="space-between" align="center">
          <!-- Header -->
          <v-col cols="12" lg="4" sm="auto" class="px-10">
            <div class="title">
              {{ $t("customer details") }}
              <span v-if="pageData.isEdit == true">: {{ form.name_ar }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="auto" class="px-10">
            <v-btn
              @click="deleteDialog = true"
              v-if="pageData.isEdit == true && pageData.editIsLoading == false && form.is_on != 0"
              :loading="pageData.isLoading"
              class="my-2 mx-2 shadow"
              color="error"
            >{{ $t("delete") }}</v-btn>
            <v-btn
              @click="restoreMethod(form.id)"
              v-if="pageData.isEdit == true && pageData.editIsLoading == false && form.is_on != 1"
              :loading="pageData.isLoading"
              class="my-2 mx-2 shadow"
              color="primary"
              outlined
            >{{ $t("restore") }}</v-btn>
            <v-btn
              @click="save"
              :loading="pageData.isLoading"
              :disabled="!valid"
              class="my-2 mx-2 shadow"
              color="success"
            >{{ $t("save customer") }}</v-btn>
          </v-col>
          <!-- End Of Header -->
        </v-row>

        <!-- Form -->
        <v-form ref="form" v-model="valid" class="pa-3">
          <v-card elevation="0" class="transparent pa-5 pt-7">
            <v-row>
              <GenericInput
                type="text"
                :value="form.name_en"
                @input="form.name_en = $event"
                label="name_en"
                :required="true"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="text"
                :value="form.name_ar"
                @input="form.name_ar = $event"
                label="name_ar"
                :required="true"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="number"
                :value="form.commercial_registration_no"
                @input="form.commercial_registration_no = $event"
                label="commercial registration no"
                :required="true"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="number"
                :value="form.tax_registration_no"
                @input="form.tax_registration_no = $event"
                label="tax registration no"
                :required="true"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="mobile"
                :value="form.phone"
                @input="form.phone = $event"
                label="phone"
                :required="false"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="email"
                :value="form.email"
                @input="form.email = $event"
                label="email"
                :required="false"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="textarea"
                :value="form.address"
                @input="form.address = $event"
                label="address"
                :required="true"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 12, 12]"
              ></GenericInput>
              <GenericInput
                type="file"
                :value="form.image"
                @input="form.image = $event"
                label="logo image"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="file"
                :value="form.invoice_image"
                @input="form.invoice_image = $event"
                label="invoice image"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
              ></GenericInput>
              <GenericInput
                type="number"
                :value="form.id"
                label="customer number"
                :required="false"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"
                v-if="pageData.isEdit"
                :disabled="true"
              ></GenericInput>
            </v-row>
          </v-card>
        </v-form>
        <!--  End Of Form -->

        <!-- Contract -->
        <v-col
          cols="12"
          lg="4"
          sm="auto"
          class="px-10"
          v-if="pageData.isLoading == false && pageData.isEdit == true"
        >
          <div class="title">{{ $t("old contracts") }}</div>
        </v-col>
        <v-simple-table v-if="pageData.isLoading == false && pageData.isEdit == true">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(header, index) in pageData.tableHeader" :key="index">{{ header.text }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in pageData.rows" :key="index">
                <td v-for="(header, y) in pageData.tableHeader" :key="y">
                  <span v-if="header.value == 'services'" class="d-flex text-uppercase">
                    <div v-for="(service, z) in row.services" :key="z">
                      {{ service.name }}
                      <span v-if="row.services.length !== z + 1">,</span>
                    </div>
                  </span>
                  <span
                    v-else-if="header.value == 'has_invoice'"
                    :class="row.has_invoice == 1 ? 'primary--text' : 'error--text'"
                  >
                    <!-- <div class="text-center" v-if="row.has_invoice == 0">{{ $t("no invoices") }}</div> -->
                    <v-btn
                      text
                      small
                      class="error--text"
                      v-if="row.has_invoice == 0"
                    >{{ $t('no invoices') }}</v-btn>
                    <v-btn
                      text
                      small
                      class="primary--text"
                      v-if="row.has_invoice == 1"
                      @click="showInvoice(row.id)"
                    >{{ $t('has invoice') }}</v-btn>
                  </span>
                  <span v-else-if="header.value == 'contract_status'">
                    <div v-if="row.has_invoice == 0">{{ $t("pending") }}</div>
                    <div v-if="row.has_invoice == 1">{{ $t("accepted") }}</div>
                    <div v-if="row.has_invoice == 2">{{ $t("rejected") }}</div>
                  </span>
                  <span v-else>{{ row[header.value] }}</span>
                </td>
              </tr>
              <tr v-if="pageData.rows.length == 0">
                <th colspan="6" class="py-3" style="border : 0">
                  <v-alert
                    class="d-block ma-auto"
                    dense
                    outlined
                    text
                    type="info"
                  >{{ $t('no old contracts') }}</v-alert>
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--  End Of Contract -->
      </v-card>
    </v-container>
    <DeleteConfirmation
      v-if="deleteDialog == true"
      :dialog="deleteDialog"
      :item="form.name_ar ? ' ' + form.name_ar : null"
      :backValueMethod="deleteMethod"
    ></DeleteConfirmation>
  </section>
</template>

<script>
import GenericInput from "../../components/GenericInput.vue"
// import DataTable from "../../components/DataTable.vue";
import DeleteConfirmation from "../../components/modals/DeleteConfirmation.vue";
export default {
  name: "CustomerControl",

  data: () => ({
    pageData: {
      url: "/",
      controlRoute: "customer",
      entityName: "customers",
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
      tableHeader: [],
      options: {},
      rows: []
    },
    valid: false,
    deleteDialog: false,

    form: {
      name_ar: null,
      name_en: null,
      commercial_registration_no: null,
      tax_registration_no: null,
      phone: "",
      email: "",
      address: null,
      image: null,
      invoice_image: null,
    },

  }),
  components: {
    GenericInput,
    DeleteConfirmation,
    // DataTable,
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },

  methods: {
    pageMainData() {
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t('contract number'),
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("contract status"),
          sortable: true,
          value: "contract_status",
        },
        {
          text: this.$i18n.t("services"),
          sortable: true,
          value: "services",
        },
        {
          text: this.$i18n.t("invoices"),
          sortable: true,
          value: "has_invoice",
        },
        {
          text: this.$i18n.t("start_date"),
          sortable: true,
          value: "start_date",
        },
        {
          text: this.$i18n.t("expiry_date"),
          sortable: true,
          value: "expiry_date",
        },
        // { text: this.$i18n.t("Settings"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: false,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      if (this.$route.params.id) {

        this.pageData.isEdit = true;
        this.pageData.editIsLoading = true;
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.entityName}/${this.$route.params.id}`).then((response) => {
          if (response.check) {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            this.form = response.data.data;
            this.pageData.rows = response.data.contracts;
            this.$refs.form.validate();
          }
        })

      }
    },


    save() {

      if (this.$refs.form.validate()) {
        console.log("form", this.form);
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = 'PUT';
          delete this.form.disable_date
          delete this.form.disable_user
          delete this.form.created_at
          delete this.form.updated_at
          let arrayData = Object.entries(this.form);
          let formData = new FormData();
          for (let i = 0; i < arrayData.length; i++) {
            formData.append(arrayData[i][0], arrayData[i][1]);
          }
          this.$api.POST_METHOD(`${this.pageData.entityName}/${this.form.id}`, formData).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url)
            }
          })
        }
        else {
          let arrayData = Object.entries(this.form);
          let formData = new FormData();
          for (let i = 0; i < arrayData.length; i++) {
            formData.append(arrayData[i][0], arrayData[i][1]);
          }
          this.$api.POST_METHOD(`${this.pageData.entityName}`, formData).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD(`${this.pageData.entityName}/${this.form.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url);
          this.pageData.isLoading = false
        })
      }
    },
    restoreMethod(id) {
      this.pageData.isLoading = true
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${id}`, null).then((response) => {
        if (response.check) {
          this.$router.push(this.pageData.url);
        }
        this.pageData.isLoading = false
      });
    },
    showInvoice(id) {
      this.$api.DOWNLOAD_METHOD(`show_customer_contract_invoice/${id}`)
    }
  },
};
</script>
