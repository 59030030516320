<template>
  <div class="text-center" id="appBarMenu">
    <v-menu
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      transition="slide-x-transition"
      bottom
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on" fab elevation="0">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-card class="shadow">
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-action>
              <v-btn
                to="/"
                active-class="primary"
                block
                color="transparent"
                depressed
              >{{ $t('customers') }}</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                to="/contracts"
                active-class="primary"
                block
                color="transparent"
                depressed
              >{{ $t('contracts') }}</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                to="/invoices"
                active-class="primary"
                block
                color="transparent"
                depressed
              >{{ $t('services invoices') }}</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn
                to="/renew-contracts"
                active-class="primary"
                block
                color="transparent"
                depressed
              >{{ $t('renew contracts') }}</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-row align="center" justify="center">
                <!-- <v-col cols="3">
                  <v-btn
                    v-on:click="changeLanguage"
                    small
                    fab
                    class="bg-white d-block ma-auto"
                    elevation="0"
                  >
                    <img
                      src="../../assets/img/svg/en.svg"
                      v-if="$vuetify.rtl == true"
                      alt="smart form"
                    />
                    <img
                      src="../../assets/img/svg/ar.svg"
                      v-if="$vuetify.rtl == false"
                      alt="smart form"
                    />
                  </v-btn>
                </v-col>-->
                <!-- <v-col cols="1">
                  <v-divider vertical style="height: 2rem"></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-btn small fab @click="darkTheme" depressed>
                    <v-icon v-if="$store.state.Settings.darkTheme">mdi-white-balance-sunny</v-icon>
                    <v-icon v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
                  </v-btn>
                </v-col>-->
                <!-- <v-col cols="1">
                  <v-divider vertical style="height: 2rem"></v-divider>
                </v-col>-->
                <v-col cols="12">
                  <v-btn
                    color="backgroundW "
                    depressed
                    block
                    style="border: 0.5px solid #EBEBEB !important"
                    @click="logout"
                  >
                    <img src="../../assets/img/png/shutdown.png" class="mx-2" alt="logout" />
                    {{ $t('logout') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<style  >
#appBarMenu .v-menu__content {
  box-shadow: none !important;
  top: 64px !important;
  box-shadow: 0px 4px 28px rgb(0 0 0 / 5%) !important;
}
#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}
</style>
<script>
import api from "../../services/ApiDataService";
export default {
  name: "AppbarMenu",
  computed: {

  },
  data: () => ({

  }),
  mounted() {

  },

  methods: {
    logout() {
      api.LOGOUT();
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },
};
</script>
