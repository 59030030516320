import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../router";
import store from "../store";
import i18n from "./i18n";
//////////////////


///////// Axios Defaults Header /////////

axios.defaults.baseURL = store.state.endpointURL + store.state.Settings.language + "/";
axios.defaults.headers.common["access-token"] = store.state.apiAccess;
axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.accessToken;
// axios.defaults.headers.common["content-type"] = "multipart/form-data" ;


export default {
  install(Vue) {
    // define a global property
    Vue.PLUGIN_VERSION = "0.0.1";
    Vue.prototype.$api = {
      serverUrl : "https://contract-api.cloudsecretsksa.com/",
      //  GET ------------------------------------------------------------
      async GET_METHOD(RESOURCE_NAME) {
        if (navigator.onLine == true) {
          try {
            const response = await axios.get(RESOURCE_NAME);
            if (response.data.check == false) {
              this.ShowModal("error", i18n.t("Error"), response.data.msg);
            }
            if (response.data) {
              return response.data;
            }
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
       },
      //  Download File ------------------------------------------------------------
      async DOWNLOAD_METHOD(RESOURCE_NAME) {
        window.open(`${store.state.endpointURL + store.state.Settings.language}/${RESOURCE_NAME}`, '_blank'  );
        // try {
        //   window.open(`${this.serverUrl}${RESOURCE_NAME}`, '_blank'  );
        //   const link = document.createElement("a");
        //   link.href = `${this.serverUrl}${RESOURCE_NAME}`;
        //   link.download = RESOURCE_NAME;
        //   link.click();
        // } catch (error) {
        //   console.log(error);
        //   this.SetError(error, error);
        // }
       },
      //  POST ------------------------------------------------------------
       async POST_METHOD(RESOURCE_NAME ,NEW_DATA ,TOAST = false, TOAST_MESSAGE = " ") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post(RESOURCE_NAME, NEW_DATA);
            if (TOAST) {
              this.ShowAlert("success", " ", TOAST_MESSAGE);
            }
            if (!TOAST && response.data.check == true) {
              this.ShowAlert("success", " ", response.data.msg);
            }
            if (response.data.check == false) {
              this.ShowAlert("error", i18n.t("Error"), response.data.msg);
              if (response.data.data) {
                Object.keys(response.data.data).forEach((key) => {
                  console.log(response.data.data[key]);
                  response.data.data[key].forEach((error) => {
                    this.ShowAlert("error", i18n.t("Error"), error);
                  });
                });
              }
            }
            return response.data;
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log('error ==>' ,error.response.status,error.response.data,error.response.headers);
              return false;
            } else if (error.request) {
              console.log('error ==>' ,error.request);
              this.SetError(null, error.request);
              return false;
            } else {
              this.SetError(null, error.message);
              console.log('error ==>' , error.message);
              return false;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
       },
      //  PUT ------------------------------------------------------------
       async PUT_METHOD(RESOURCE_NAME ,ID ,NEW_DATA ,TOAST = false,TOAST_MESSAGE = " ") {
         try {
           const response = await axios.put(RESOURCE_NAME + "/" + ID, NEW_DATA);
           console.log("response.data", response.data);
           if (TOAST) {
             this.ShowAlert("success", " ", TOAST_MESSAGE);
           }
           return response.data;
         } catch (error) {
           console.log(error);
           this.SetError(error.response.status, error);
         }
       },
      //  DELETE ------------------------------------------------------------
       async DELETE_METHOD(RESOURCE_NAME, ID, TOAST = false, TOAST_MESSAGE = " ") {
         try {
           const response = await axios.delete(RESOURCE_NAME + "/" + ID);
           console.log("response.data", response.data);
           if (TOAST) {
             this.ShowAlert("success", " ", TOAST_MESSAGE);
           }
           return response.data;
         } catch (error) {
           console.log(error);
           this.SetError(error.response.status, error);
         }
       },
   //   LOGIN ------------------------------------------------------------
       async LOGIN_AUTH(LOGIN_DATA, TOAST = true, TOAST_MESSAGE =  store.state.Settings.translation['Welcome Back']) {
         try {
           const response = await axios.post(`login`,LOGIN_DATA);
           if(response.data.check == false){
             this.ShowAlert("error", store.state.Settings.translation["Wrong Username or Password"] , response.data.msg);
           }else{
             if (TOAST) {
               this.ShowAlert("success",  store.state.Settings.translation['Logged in successfully'], TOAST_MESSAGE);
             }
             this.SET_AUTH(response.data.data);
           }
           return response.data;
         } catch (error) {
           console.log(error);
           this.SetError(error.response.status, error);
         }
       },
     
       ///////// Set Auth Data /////////
       SET_AUTH(AUTH_DATA) {
         console.log("AUTH_DATA", AUTH_DATA);
         localStorage.setItem("access-token", AUTH_DATA.token);
         axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_DATA.token;
         localStorage.setItem("full_name", AUTH_DATA.name);
         localStorage.setItem("email", AUTH_DATA.email);
         store.state.isAuth= true;
         store.state.accessToken = "Bearer " + AUTH_DATA.token;
         router.push("/");
         return AUTH_DATA;
       },
       
       ///////// Check Internet Connection /////////
        CHECK_INTERNET(){
          return navigator.onLine
       },
       ///////// Logout /////////
       LOGOUT() {
         store.state.isAuth= false;
         localStorage.removeItem("access-token");
         localStorage.removeItem("full_name");
         localStorage.removeItem("email");
         router.push("/login");
       },
     
       ///////// API Errors Action /////////
       SetError(STATUS, ERROR) {
        switch (STATUS) {
          case 401:
            // not auth
            this.LOGOUT(true);
            break;
          case 404:
            // not found
            router.push("/notfound");
            break;
          case 403:
            // not Auth
            this.LOGOUT(true);
            break;
          case 500:
            // server error
            this.ShowModal(
              "error",
              i18n.t("error happend"),
              i18n.t(
                "Try to refresh the page or feel free to contact us if the problem persists"
              )
            );
            break;

          default:
            this.ShowModal("error", "Request error", ERROR);
            break;
        }
       },
     
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: true,
          timer: 3000,
          position:
            store.state.Settings.translation.rtl == true
              ? "top-end"
              : "top-start",
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },

      ///////// Modal /////////
      ShowModal(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: false,
          timer: 3000,
          showConfirmButton: false,
          showCloseButton: false,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
    };
  },
};
