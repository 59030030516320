// import en from 'vuetify/lib/locale/en'

export default {
  // ...en,

  darkMode: "Dark Mode",
  english: "العربية",
  profile: "Profile",
  home: "Home",
  sales: "SalesQTY",
  usedInvoice: "Used Invoice",
  startShift: "Start the Shift",
  endShift: "End the Shift",
  branch: "Branch",
  changeBranch: "Change Branch",
  finalCash: "Final Cash",
  Caliber: "Caliber",
  caliber: "Caliber",
  salesInvoices: "Sales Invoices",
  usedInvoices: "Used Invoices",
  price: "Price",
  weight: "Weight",
  total: "Total",
  lastUpdate: "Last Update",
  code: "Code",
  customerName: "Customer Name",
  print: "Print",
  customerMobile: "Customer Mobile",
  vat: "Vat",
  grandTotal: "Grand Total",
  view: "View",
  from: "from",
  to: "To",
  createSalesInvoice: "Create Sales Invoice",
  delete: "Delete",
  item: "Item",
  create: "Create",
  countPices: "Count Pices",
  save: "Save",
  clear: "Clear",
  gold: "Gold",
  countSalesInvoices: "Count Sales Invoices",
  countUsedInvoices: "Count Used Invoices",
  totalSalesCash: "Total Sales Cash",
  totalSalesMachine: "Total Sales Machine",
  totalUsedCash: "Total Used Cash",
  finalTotalCash: "Final Total Cash",
  remining: "Remining",
  totalPaid: "Total Paid",
  noMachine: "No Machine",
  noAgent: "No Agent",
  payCash: "Pay Cash",
  payBank: "Pay Bank",
  payCashBank: "Pay Cash & Bank",
  deletePaid: "Delete Paid",
  saveInvoice: "Save Invoice",
  ok: "OK",
  login: "Login",
  logintoQaratPOSSystem: "Login to Qarat POS System ",
  logout: "Logout",
  userName: "User Name",
  password: "Password",
  money: "Money",
  ryal: "SAR",
  invoiceList: "Invoice List",
  add: "Add",
  salesInvoice: "Sales invoice",
  purchaseInvoice: "Purchase invoice",
  createaPurchaseInvoice: "Create a purchase invoice",
  pleaseEnterWeight: "Please enter weight",
  pleaseEnterThePrice: "Please enter the price",
  chooseTheProductToSell: "Choose the product to sell",
  notes: "Notes",
  PleaseEnterThecustomerName: "Please enter the customer's name",
  idNumber: "ID number",
  mobileNumber: "Mobile Number",
  modify: "Modify",
  gram: "Gram",
  totalweight: "Total weight",
  next: "Next",
  prev: "Prev",
  notAvail: "Not available",
  changeSellar: "Change Sellar",
  changeMachine: "Change Machine",
  pleaseEnterTheAmountPaidByTheNetwork:"Please enter the amount paid by the network",
  theamountrequired:"The amount required",
  noItemsAdded :"No items was added",
  noInvoicesAdded :"No invoices was added",
  priceOriginal:"Original Price",
  lessPrice:"Less Price",
  sellPrice:"Sell Price",
  qty:"Count Pices",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  0: "0",
  goldPrice: "Gold Price",
  "Username is required" : "Username is required",
  "Password is required" : "Password is required",
  "Password must be greater than 5 characters" : "Password must be greater than 5 characters",
  "Save":"Save",
  "Ok":"Ok",
  "Delete":"Delete",
  "Remove":"Remove",
  "Clear":"Clear",
  "Search":"Search",
  "Login":"Login",
  "Logout":"Logout",
  "Log out": "Log out",
  "Email":"Email",
  "Email Address":"Email Address",
  "Password":"Password",
  "Forget Password":"Forget Password",
  "Remember me":"Remember me",
  "Successfully":"Successfully",
  "Error":"Error",
  "Excel":"Excel",
  "Import":"Import",
  "Export":"Export",
  "Category":"Category",
  "Categories":"Categories",
  "Invoices":"Invoices",
  "Invoice":"Invoice",
  "Sales invoice":"Sales invoice",
  "Sales invoices":"Sales invoices",
  "Total":"Total",
  "Total Price":"Total Price",
  "Price":"Price",
  "Purchase invoice":"Purchase invoice",
  "Purchase invoices":"Purchase invoices",
  "Welcome":"Welcome",
  "Welcome Back":"Welcome Back",
  "I Don't have an account": "I Don't have an account",
  "or": "or",
  "OR": "OR",
  "Login with": "Login with",
  "Back": "Back",
  "Skip": "Skip",
  "Name": "Name",
  "name": "Name",
  "Send message": "Send message",
  "Code": "Code",
  "Enter New Password": "Enter New Password",
  "I Have an account": "I Have an account",
  "Good Morning": "Good Morning",
  "Good Afternoon": "Good Afternoon",
  "Good Evening": "Good Evening",
  "Good Noon": "Good Noon",
  "Date": "Date",
  "date": "Date",
  "Tax": "Tax",
  "tax": "Tax",
  "taxes": "taxes",
  "Taxes": "Taxes",
  "Confirm": "Confirm",
  "Services": "Services",
  "services": "Services",
  "service": "Service",
  "Service": "Service",
  "Account": "Account",
  "Edit": "Edit",
  "Settings": "Settings",
  "Language": "Language",
  "Canceled": "Canceled",
  "Address": "Address",
  "Phone": "Phone",
  "Type": "Type",
  "Status": "Status",
  "Cancel": "Cancel",
  "Sorry": "Sorry",
  "Done": "Done",
  "Rquired": "Rquired",
  "Continue": "Continue",
  "Please enter email address": "Please enter email address",
  "Please enter valid email address": "Please enter valid email address",
  "Please enter mobile number": "Please enter mobile number",
  "Please enter valid mobile number": "Please enter valid mobile number",
  "Please enter valid number": "Please enter valid number",
  "Please enter your password": "Please enter your password",
  "Minimum length is 8 characters": "Minimum length is 8 characters",
  "Maximum length is 12 characters": "Maximum length is 12 characters",
  "Password not matched": "Password not matched",
  "Product": "Product",
  "product": "Product",
  "Products": "Products",
  "products": "products",
  "Product summary": "Product summary",
  "Product description": "Product description",
  "Pages":"Pages",
  "Page":"Page",
  "Next":"Next",
  "Prev":"Prev",
  "Vat":"Vat",
  "Print":"Print",
  "Action":"Action",
  "Actions":"Actions",
  "Home":"Home",
  "Branch":"Branch",
  "Branches":"Branches",
  "branches":"Branches",
  "lorem title 1":"Lorem Ipsum",
  "lorem title 2":"Neque porro",
  "lorem title 3":"Dolor sit amet",
  "lorem title 4":"Consectetur",
  "lorem title 5":"Adipisci velit",
  "lorem body 1":"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.",
  "lorem body 2":"If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
  "lorem body 3":"It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures.",
  "lorem body 4":"Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  "lorem body 5":"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
  "Copyright":"All Copyright Reserved ©",
  "Privacy & terms":"Privacy & terms",
   "Privacy Policy":"",
   "Terms & Conditions":"Terms & Conditions",
   "FAQ":"FAQ",
   "About US":"About US",
   "Contact US":"Contact US",
   "Field is required":"Field is required",
   "Invoice Created Successfully" : "Invoice Created Successfully",
   "Logged in successfully":"Logged in successfully",
   "Please Start Shift":"Please Start Shift",
   "Wrong Username or Password":"Wrong Username or Password",
   "No Results":"No Results",
   "here":"Here",
   "Welcome to" : "Welcome to",
   "Qarat System" : "Qarat System",
   "favourites":"Favourites",
   "gold price today":"Gold price today",
   "more":"More",
   "Total gold sales":"Total gold sales",
   "gold sales":"gold sales",
   "gold purchases":"Gold purchases",
   "number of orders":"Number of orders",
   "order":"Order",
   "Users":"Users",
   "Loading... Please wait" : "Loading... Please wait",
   "add this page to favourites" : "Add this page to favourites",
   "in your favourites" : "In your favourites",
   "add new user":"Add new user",
   "fullname" :"Fullname",
   "job":"Job",
   "Fiscal year":"Fiscal year",
   "Daily restrictions":"Daily restrictions",
   "Daily restriction":"Daily restriction",
   "add Daily restriction":"Add Daily restriction",
   "Transactions":"Transactions",
   "Accounting":"Accounting",
   "Analytical statement":"Analytical Statement",
   "Trial Balance":"Trial Balance",
   "Accounts Menu":"Accounts Menu",
   "Cost Center":"Cost Center",
   "Cost Center Code":"Cost Center Code",
   "Bulk Delete Rows":"Bulk Delete Rows",
   "Delete Selected rows":"Delete Selected rows",
   "restriction number":"Restriction number",
   "restriction description":"Restriction description",
   "restriction date":"Restriction date",
   "month":"Month",
   "daily":"Daily",
   "created_at":"Created at",
   "debtor":"Debtor",
   "creditor":"Creditor",
   "description_en":"Description in English",
   "description_ar":"Description in Arabic",
   "transaction_description_en":"Transaction Description in English",
   "transaction_description_ar":"Transaction Description in Arabic",
   "restriction_en":"Restriction in English",
   "restriction_ar":"Restriction in Arabic",
   "new line":"New Line",
   "difference":"The difference",
   "invoice date":"Invoice date",
   "account code": "Account code",
   "account name": "Account name",
   "account": "Account",
   "users permissions" : "Users permissions",
   "search in all users and control their permissions":"Search in all users and control their permissions",
   "all":"All",
   "inventory":"Inventory",
   "delivery":"Delivery",
   "security":"Security",
   "force delete":"Force delete",
   "Last Activity":"Last Activity",
   "Users Permissions":"Users Permissions",
   "codes":"Codes",
   "reports":"Reports",
   "transactions":"Transactions",
  };
