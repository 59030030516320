<template>
  <section>
    <v-container fluid>
      <v-card elevation="0" class="ma-0 shadow mt-5 pa-0 backgroundW rounded-lg">
        <v-row justify="space-between" align="center">
          <!-- Header -->

          <v-col cols="12" sm="auto" class="px-10 d-flex align-center">
            <v-text-field
              v-model="pageData.search"
              @keydown.enter="(e) => getSearch(e.target.value)"
              @click:clear="(e) => getSearch(e.target.value)"
              @click:prepend-inner="getSearch(pageData.search)"
              prepend-inner-icon="mdi-magnify"
              :placeholder="
                pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('Search')
              "
              style="min-width: 350px;"
              single-line
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- End Of Header -->
          <!-- Table -->
          <v-col cols="12" sm="12" class="px-3 pt-0">
            <DataTable
              :isLoading="pageData.isLoading"
              :options="pageData.options"
              :data="pageData.rows"
              :header="pageData.tableHeader"
              :backValueMethod="setClickRow"
              :editValueMethod="edit"
              :deleteValueMethod="deleteMethod"
              :sendContract="chooseTypeDialog"
              :restoreValueMethod="restoreMethod"
              :pageData="pageData"
            ></DataTable>
          </v-col>
          <v-col cols="12" sm="12" v-if="!pageData.isLoading">
            <Pagination
              :pagination="pageData.pagination"
              :limit="pageData.rowsNumber"
              :backValueMethod="changePage"
              :isLoading="pageData.isLoading"
            ></Pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <SendContractVue :dialog="dialog" :backValueMethod="sendContract" />
  </section>
</template>

<script>

import DataTable from "../../components/DataTable.vue";
import Pagination from "../../components/ui/Pagination.vue";
import SendContractVue from "../../components/modals/SendContract.vue";
export default {
  name: "ContractsRenew",

  data: () => ({
    pageData: {
      url: "/renew-contracts",
      controlRoute: "renew-contract",
      entityName: "expired_contracts",
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},

    },
    tab: null,
    selectedItem: null,
    dialog: false,
    activeTab: "new contracts",
    tabsItems: [
      'new contracts', 'active contracts', 'ended contracts', 'deleted contracts'
    ]
  }),
  components: {
    DataTable,
    Pagination,
    SendContractVue
  },
  computed: {
    status() {
      switch (this.activeTab) {
        case "new contracts":
          return 1
        case "active contracts":
          return 6
        case "ended contracts":
          return 7
        case "deleted contracts":
          return 0
        default:
          return 1;
      }
    }
  },
  watch: {
    status() {
      if (this.status == 0) {
        this.pageData.isTrashed = 0;
      }
      else {
        this.pageData.isTrashed = 1;
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t("contract number"),
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("customer name"),
          sortable: true,
          value: "customer_name",
        },
        {
          text: this.$i18n.t("customer mobile"),
          sortable: true,
          value: "phone",
        },
        {
          text: this.$i18n.t("expiry_date"),
          sortable: true,
          value: "expiry_date",
        },
        { text: this.$i18n.t("renew"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: false,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
        renew: true,

      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {

      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(
          `${this.pageData.entityName}?status=${this.status}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
        )
        .then((response) => {
          if (response.check) {
            console.log(response);
            this.pageData.rows = response.data.expired_contracts.data;
            this.pageData.page = response.data.expired_contracts.current_page;
            this.pageData.pagination = {
              page: response.data.expired_contracts.current_page,
              totalPages: response.data.expired_contracts.last_page,
              per_page: response.data.expired_contracts.per_page,
              totalRows: response.data.expired_contracts.total,
            };
            this.pageData.isLoading = false;
          }
        });

    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`${this.pageData.entityName}/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    chooseTypeDialog(item) {
      this.dialog = true;
      this.selectedItem = item
    },
    sendContract(type) {
      console.log(type);
      this.dialog = false
      this.$api.POST_METHOD(`send_contract/${this.selectedItem.id}`, { type: type }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {

    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>

<style lang="scss">
.activeTab {
  background: transparent !important;
  border-bottom: 2px solid #2d68b5 !important;
}
</style>
