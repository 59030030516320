<template>
  <section>
    <v-container fluid>
      <!-- Form -->
      <v-form ref="contractForm" v-model="valid">
        <v-row justify="center" v-if="pageData.isLoading">
          <v-col cols="auto" class="my-15">
            <v-progress-circular
              :size="70"
              :width="5"
              style="margin: auto"
              class="d-block"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p class="text-center">{{ $t("Loading data") }}</p>
          </v-col>
        </v-row>
        <v-row v-if="!pageData.isLoading">
          <v-col cols="12" md="8">
            <v-card elevation="0" class="pa-5 shadow rounded">
              <GenericInput
                type="autocomplete"
                :lookups="customers"
                selected_label="name_ar"
                selected_prop="id"
                v-if="!form.customer_id"
                :value="form.customer_id"
                @input="form.customer_id = $event"
                label="select customer"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :hideDetails="true"
                :required="true"
              ></GenericInput>
              <v-row v-if="form.customer_id && selectedCustomer">
                <v-col cols="12" class="d-flex align-center">
                  <div class="subtitle-1 font-weight-bold">{{ $t("customer data") }}</div>
                  <v-btn
                    plain
                    small
                    color="primary"
                    @click="() => form.customer_id = null"
                    class="mx-1"
                  >{{ $t("change customer") }}</v-btn>
                </v-col>
                <GenericInput
                  type="text"
                  :value="selectedCustomer.name_en"
                  @input="selectedCustomer.name_en = $event"
                  label="name_en"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="text"
                  :value="selectedCustomer.name_ar"
                  @input="selectedCustomer.name_ar = $event"
                  label="name_ar"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="number"
                  :value="selectedCustomer.commercial_registration_no"
                  @input="selectedCustomer.commercial_registration_no = $event"
                  label="commercial registration no"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="number"
                  :value="selectedCustomer.tax_registration_no"
                  @input="selectedCustomer.tax_registration_no = $event"
                  label="tax registration no"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="mobile"
                  :value="selectedCustomer.phone"
                  @input="selectedCustomer.phone = $event"
                  label="phone"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="email"
                  :value="selectedCustomer.email"
                  @input="selectedCustomer.email = $event"
                  label="email"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                />
                <GenericInput
                  type="textarea"
                  :value="selectedCustomer.address"
                  @input="selectedCustomer.address = $event"
                  label="address"
                  :loading="pageData.isLoading"
                  :cols="[12, 12, 12]"
                  :readonly="true"
                />
                <GenericInput
                  type="file"
                  :value="selectedCustomer.image"
                  @input="selectedCustomer.image = $event"
                  label="logo image"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                  :disabled="true"
                />
                <GenericInput
                  type="file"
                  :value="selectedCustomer.invoice_image"
                  @input="selectedCustomer.invoice_image = $event"
                  label="invoice image"
                  :loading="pageData.isLoading"
                  :cols="[12, 6, 6]"
                  :readonly="true"
                  :disabled="true"
                />
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card elevation="0" class="pa-5 shadow rounded">
              <div
                class="subtitle-1 mb-3 font-weight-bold text--disabled"
              >{{ $t("date & duration") }}</div>
              <GenericInput
                type="date"
                :value="form.creation_date"
                @input="form.creation_date = $event"
                label="creation date"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>
              <GenericInput
                type="date"
                :value="form.start_date"
                @input="form.start_date = $event"
                label="contract start date"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>

              <GenericInput
                type="select"
                :lookups="[{ id: 1, name: $i18n.t('1 year') }, { id: 2, name: $i18n.t('2 years') }, { id: 3, name: $i18n.t('3 years') }, { id: 4, name: $i18n.t('4 years') }]"
                selected_label="name"
                selected_prop="id"
                :value="form.duration"
                @input="form.duration = $event"
                label="duration"
                :multi="false"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>
            </v-card>
            <v-card elevation="0" class="pa-5 mt-5 shadow rounded">
              <div class="subtitle-1 mb-3 font-weight-bold text--disabled">{{ $t("services") }}</div>
              <GenericInput
                type="text"
                :value="form.branch_name"
                @input="form.branch_name = $event"
                label="branch name"
                :required="true"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
              ></GenericInput>
              <GenericInput
                type="text"
                :value="form.company_id"
                @input="form.company_id = $event"
                label="company number"
                :required="true"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
              ></GenericInput>
              <GenericInput
                type="select"
                :lookups="services"
                selected_label="name"
                selected_prop="id"
                :value="form.services"
                @input="form.services = $event"
                label="services"
                :multi="true"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>
              <GenericInput
                type="number"
                :value="form.amount"
                @input="form.amount = $event"
                label="amount"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>
            </v-card>
            <v-card elevation="0" class="pa-5 mt-5 shadow rounded" v-if="pageData.isEdit == true">
              <div
                class="subtitle-1 mb-3 font-weight-bold text--disabled"
              >{{ $t("contract status") }}</div>
              <GenericInput
                type="select"
                :lookups="[{ id: 2, name: $i18n.t('pending') }, { id: 3, name: $i18n.t('accepted') }, { id: 4, name: $i18n.t('rejected') }, { id: 5, name: $i18n.t('expired') }]"
                selected_label="name"
                selected_prop="id"
                :value="form.contract_status"
                @input="form.contract_status = $event"
                label="select status"
                :multi="false"
                :loading="pageData.isLoading"
                :cols="[12, 12, 12]"
                :required="true"
              ></GenericInput>
              <v-col cols="12" class="pa-0 d-flex justify-start align-center">
              <label>{{ $t('activate contract') }}</label>
              <!--   @mouseup="toggleActiveMethod(item)" -->
                <v-switch
                  inset
                  color="primary"
                  hide-details
                  dense
                  class="my-auto mx-2 d-block"
                  v-model="form.contract_active"
                ></v-switch>
              </v-col>
            </v-card>
            <v-col cols="12">
              <v-btn
                depressed
                :loading="pageData.isLoading"
                :disabled="!valid"
                class="d-block mi-start-auto"
                color="primary"
                @click="save"
              >{{ pageData.isEdit == true ? $t('edit contract') : $t('create contract') }}</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-form>
      <!--  End Of Form -->

      <!-- Contract -->

      <!--  End Of Contract -->
    </v-container>
    <DeleteConfirmation
      v-if="deleteDialog == true"
      :dialog="deleteDialog"
      :item="form.name_ar ? ' ' + form.name_ar : null"
      :backValueMethod="deleteMethod"
    ></DeleteConfirmation>
  </section>
</template>

<script>
import GenericInput from "../../components/GenericInput.vue"
import DeleteConfirmation from "../../components/modals/DeleteConfirmation.vue";
export default {
  name: "ContractControl",

  data: () => ({
    pageData: {
      url: "/contracts",
      controlRoute: "contract",
      entityName: "contracts",
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
      tableHeader: [],
      options: {},
      rows: []
    },
    valid: false,
    deleteDialog: false,
    services: [],
    customers: [],
    form: {
      customer_id: null,
      start_date: null,
      creation_date: null,
      company_id: null,
      duration: null,
      branch_name: null,
      services: null,
      amount: null,
    },

  }),
  components: {
    GenericInput,
    DeleteConfirmation,
  },
  computed: {
    selectedCustomer() {
      if (this.form.customer_id) {
        return this.$global.FilterArrayOfObject(this.customers, 'id', this.form.customer_id)[0]
      } else {
        return null
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  watch: {
  },
  methods: {
    pageMainData() {

    },
    getData() {
      if (this.$route.params.id) {
        this.pageData.isEdit = true;
        this.pageData.editIsLoading = true;
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.entityName}/${this.$route.params.id}`).then((response) => {
          this.pageData.editIsLoading = false;
          this.pageData.isLoading = false;
          if (response.check) {
            this.form = response.data.contract;
            this.services = response.data.services;
            this.customers.push(response.data.contract.customer)
            this.form.services = response.data.contract.services.split(',').map(i => Number(i));
            this.selectedCustomer = response.data.contract.customer;
            console.log('this.form', this.form);

            this.$refs.contractForm.validate();
          }
        })
      }
      else {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`${this.pageData.entityName}/create`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.services = response.data.services;
            this.customers = response.data.customers;
          }
        })
      }

    },


    save() {
      if (this.$refs.contractForm.validate() == true) {
        console.log("form", this.form);
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = 'PUT';
          // this.form.services = this.form.services.toString();
          delete this.form.customer;
          delete this.form.created_at;
          delete this.form.updated_at;
          this.$api.POST_METHOD(`${this.pageData.entityName}/${this.form.id}`, this.form).then((response) => {
            if (response.check) {
              this.pageData.isLoading = false;
              this.$router.push(this.pageData.url)
            }
          })
        }
        else {
          this.$api.POST_METHOD(`${this.pageData.entityName}`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD(`${this.pageData.entityName}/${this.form.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url);
          this.pageData.isLoading = false
        })
      }
    },
    toggleActiveMethod() {
       this.pageData.isLoading = true
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${this.form.id}`, null).then(() => {
           this.getData()
        // if (response.check) {
        //   this.getData();
        // }
      });
    },
    restoreMethod(id) {
      this.pageData.isLoading = true
      this.$api.POST_METHOD(`${this.pageData.entityName}_toggle_active/${id}`, null).then((response) => {
        if (response.check) {
          this.$router.push(this.pageData.url);
        }
        this.pageData.isLoading = false
      });
    },

  },
};
</script>
