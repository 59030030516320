var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-0 backgroundW",class:_vm.options.handleClickRow ? 'hoverRow' : '',attrs:{"headers":_vm.header,"items":_vm.isLoading == false ? _vm.data : [],"disable-pagination":"","calculate-widths":"","hide-default-header":_vm.isLoading,"loading":_vm.isLoading,"loading-text":_vm.$i18n.t('Loading... Please wait'),"no-data-text":_vm.$i18n.t('No data available'),"sort-by":_vm.options.sortBy,"hide-default-footer":"","disable-sort":"","search":_vm.searchValue ? _vm.searchValue : _vm.search,"show-select":_vm.options.selectRow},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"body.append",fn:function(){return [(_vm.pageData.footerRow)?_c('tr',{staticClass:"light"},_vm._l((_vm.pageData.footerRow),function(footer,i){return _c('td',{key:i,staticClass:"font-weight-bold light",class:footer.class,attrs:{"colspan":footer.colspan}},[_vm._v(_vm._s(footer.value))])}),0):_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[(_vm.options.switch == true)?_c('v-switch',{staticClass:"my-auto",attrs:{"inset":"","color":"indigo darken-2","hide-details":"","dense":""},on:{"mouseup":function($event){return _vm.toggleActiveMethod(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}):_vm._e(),(_vm.options.sendContract)?_c('v-tooltip',{attrs:{"bottom":"","color":"light"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-2 cursor_pointer",staticStyle:{"height":"13px"},attrs:{"src":require("../assets/img/svg/send-icon.svg"),"alt":"qarat erp"},on:{"click":function($event){return _vm.sendContract(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('send invoice')))])]):_vm._e(),(_vm.options.renew)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"color":"success","depressed":"","small":""},on:{"click":function($event){return _vm.editValueMethod(item)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('renew')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('renew')))])]):_vm._e(),(_vm.options.print)?_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-2 cursor_pointer",staticStyle:{"height":"18px"},attrs:{"src":require("../assets/img/svg/print-icon.svg"),"alt":"qarat erp"},on:{"click":function($event){return _vm.print(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('print')))])]):_vm._e(),(_vm.isLoading == true)?_c('div',[_c('v-icon',{staticClass:"mdi-spin mdi-dark"},[_vm._v("mdi-loading")])],1):_vm._e(),(_vm.options.edit && _vm.isLoading == false)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-2 cursor_pointer",staticStyle:{"height":"18px"},attrs:{"src":require("../assets/img/svg/edit-icon.svg"),"alt":"qarat erp"},on:{"click":function($event){return _vm.editValueMethod(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]):_vm._e(),(_vm.options.delete && _vm.pageData.isTrashed == 1 && _vm.isLoading == false)?_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-2 cursor_pointer",staticStyle:{"height":"18px"},attrs:{"src":require("../assets/img/svg/delete-icon.svg"),"alt":"qarat erp"},on:{"click":function($event){_vm.dialogDeleteItem = item, _vm.deleteDialog = true}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])]):_vm._e(),(_vm.options.restore && _vm.pageData.isTrashed == 0 && _vm.isLoading == false)?_c('v-tooltip',{attrs:{"bottom":"","color":"light"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-2 cursor_pointer",staticStyle:{"height":"20px"},attrs:{"src":require("../assets/img/svg/restore-icon.svg"),"alt":"qarat erp"},on:{"click":function($event){return _vm.restoreValueMethod(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('restore')))])]):_vm._e()],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"auto","tile":""}},[(item.image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$api.serverUrl + item.image),expression:"$api.serverUrl + item.image"}],staticClass:"ma-auto d-block",staticStyle:{"max-height":"35px"},attrs:{"alt":"erp"}}):_vm._e(),(!item.image)?_c('v-icon',[_vm._v("mdi-file-image-outline")]):_vm._e()],1)]}},{key:"item.main_image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"auto","tile":""}},[(item.main_image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$api.serverUrl + item.main_image),expression:"$api.serverUrl + item.main_image"}],staticStyle:{"max-height":"35px"},attrs:{"alt":"erp"}}):_vm._e(),(!item.main_image)?_c('v-icon',[_vm._v("mdi-file-image-outline")]):_vm._e()],1)]}},{key:"item.add_date",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(new Date(item.add_date).toLocaleDateString( ("" + (_vm.$vuetify.rtl == true ? "ar-EG" : "en-UK")), { weekday: "short", year: "numeric", month: "numeric", day: "numeric", } ))+" ( "+_vm._s(new Date(item.add_date).toLocaleTimeString(("" + (_vm.$vuetify.rtl == true ? "ar-EG" : "en-UK"))))+" ) ")])]}},{key:"item.expiry_date",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(new Date(item.expiry_date).toLocaleDateString( ("" + (_vm.$vuetify.rtl == true ? "ar-EG" : "en-UK")), { weekday: "short", year: "numeric", month: "numeric", day: "numeric", } ))+" ( "+_vm._s(new Date(item.expiry_date).toLocaleTimeString(("" + (_vm.$vuetify.rtl == true ? "ar-EG" : "en-UK"))))+" ) ")])]}},{key:"item.send_contract",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","color":"primary","small":"","disabled":!(item.contract_status == 1)},on:{"click":function($event){return _vm.sendContract(item)}}},[_vm._v(_vm._s(_vm.$t('send to customer')))])]}},{key:"item.tax_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$global.DecimalFormatter(Number(item.tax_amount).toFixed(2))))]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$global.DecimalFormatter(Number(item.total).toFixed(2))))]}},{key:"item.total_without_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$global.DecimalFormatter(Number(item.total_without_tax).toFixed(2))))]}},{key:"item.contract_active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"ma-auto d-block",attrs:{"inset":"","color":"primary","hide-details":"","dense":""},on:{"mouseup":function($event){return _vm.toggleActiveMethod(item)}},model:{value:(item.contract_active),callback:function ($$v) {_vm.$set(item, "contract_active", $$v)},expression:"item.contract_active"}})],1)]}},{key:"item.contract_status",fn:function(ref){
var item = ref.item;
return [(item.contract_status == 0)?_c('v-chip',{attrs:{"color":"error","outlined":"","small":""}},[_vm._v(_vm._s(_vm.$t("deleted")))]):_vm._e(),(item.contract_status == 1)?_c('v-chip',{attrs:{"color":"success","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("new")))]):_vm._e(),(item.contract_status == 2)?_c('v-chip',{attrs:{"color":"warning","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("pending")))]):_vm._e(),(item.contract_status == 3)?_c('v-chip',{attrs:{"color":"black","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("accepted")))]):_vm._e(),(item.contract_status == 4)?_c('v-chip',{attrs:{"color":"error","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("rejected")))]):_vm._e(),(item.contract_status == 5)?_c('v-chip',{attrs:{"color":"primary","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("expired")))]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.deleteDialog == true)?_c('DeleteConfirmation',{attrs:{"dialog":_vm.deleteDialog,"item":_vm.dialogDeleteItem.name ? _vm.dialogDeleteItem.name : null,"backValueMethod":_vm.deleteValue}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }