<template>
  <v-container fluid class="d-flex align-center" style="height: 100vh; overflow: hidden;">
    <v-container class="pa-0" fluid>
      <v-row justify="space-between" style="height: 100vh; ">
        <v-col cols="12" lg="5" md="7" xs="12" class="pa-0 d-flex align-self-center">
          <v-card class="login-card backgroundW pa-5" elevation="0">
            <v-col cols="12">
              <img
                src="../assets/logo.svg"
                height="118.38"
                class="d-block ma-auto"
                alt="smart form"
              />
            </v-col>
            <v-form ref="form" class="row justify-center" v-model="valid" lazy-validation>
              <v-col cols="10">
                <h3 class="text-h5 font-weight-black">{{ $t('login') }}</h3>
              </v-col>
              <v-col cols="10">
                <h5 class="mb-3">{{ $t('userName') }}</h5>
                <v-text-field
                  hide-details="auto"
                  class="my-2 rounded-lg"
                  filled
                  :rules="$store.state.Settings.usernameRule"
                  v-model="username"
                  rounded
                  @keydown.enter="login()"
                  color="primary"
                  prepend-inner-icon="mdi-account-check"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <h5 class="mb-3">{{ $t('password') }}</h5>
                <v-text-field
                  hide-details="auto"
                  class="my-2 rounded-lg"
                  filled
                  required
                  color="primary"
                  :rules="$store.state.Settings.passwordRule"
                  v-model="password"
                  rounded
                  @keydown.enter="login()"
                  :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="show ? 'text' : 'password'"
                  prepend-inner-icon="mdi-account-key"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-row justify="space-between" align="center">
                  <v-col cols="6" class="ma-0 py-0">
                    <v-checkbox v-model="checkbox" color="primary" :label="$i18n.t('Remember me')"></v-checkbox>
                  </v-col>
                  <v-col cols="auto" sm="auto">
                    <v-btn
                      @click="login"
                      :loading="loading"
                      :disabled="!valid"
                      class="mi-start-auto shadow"
                      color="primary"
                    >{{ $t('login') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" lg="7" md="7" xs="12" class="primary d-md-block d-none pa-0">
          <v-card
            color="transparent"
            class="pa-5 rounded-0 imgCard"
            elevation="0"
            style="height: 100vh;"
          >
            <v-col cols="12">
              <img
                src="../assets/img/png/Artboard.png"
                class="ma-auto d-block"
                style="height: 100vh;"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../assets/img/png/pattern@2x.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  // background: #fff;

  .imgCard {
    background-image: url("../assets/img/png/pattern@2x.png");
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .bgLog {
    background: transparent;
    background-image: url("../assets/img/png/pattern@2x.png");
    background-attachment: inherit;
    background-repeat: no-repeat;
    // background-position: 50%;
    background-size: cover;
    height: 105vh;
  }
  .login-card {
    background: transparent;
    background-image: url("../assets/img/png/pattern@2x.png");
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
    border: 0 !important;
    overflow: hidden;
    height: 100%;
    box-shadow: none !important;
    img {
      max-height: 100px;
    }
  }
}
</style>
<script>



export default {
  name: "LoginPage",
  computed: {

  },
  components: {

  },
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      checkbox: false,
      username: null,
      password: null,
    }
  },
  methods: {
    login() {

      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true) {
        const requstBody = {
          name: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requstBody).then(() => {
          this.loading = false;
        })
      }
      else {
        this.loading = false;
      }

    },

  },
};
</script>
